import { zodResolver } from '@hookform/resolvers/zod';
import {
  ContentSwitch,
  Flex,
  FormFeedback,
  FormField,
} from '@ironhack/design-system2/components';
import { omit, pick } from 'ramda';
import { ReactElement, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  ContactDetailsFields,
  FormSubmit,
  PhoneField,
  TextareaField,
} from '@/components';
import { useFormRequest, useGtmLocalStorage, usePageContext } from '@/hooks';
import { contactFormTags, sendEvent } from '@/lib/gtm';
import { regionToCampusMap, regionToCountry } from '@/lib/utils';
import type { ContactForm as ContactFormType } from '@/modules';
import { SelectField } from '../select-field';

import type { FlexProps } from '@ironhack/design-system2/components';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: { phoneNumber: string; country: string };
  message: string;
  newsletter: boolean;
  selection: {
    track: string;
    courseType: 'online' | 'in_person';
    campus: string;
  };
};

type Props = {
  contactForm: ContactFormType;
  onSuccess: () => void;
  onStep2: () => void;
} & FlexProps;

const getSchema = (messages: ContactFormType) =>
  z
    .object({
      email: z.string().email(messages.emailInvalidMessage),
      firstName: z.string().trim().min(2, messages.firstNameRequiredMessage),
      lastName: z.string().trim().min(2, messages.lastNameRequiredMessage),
      message: z.string().trim().min(2, messages.inquiryRequiredMessage),
      newsletter: z.boolean(),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phoneRequiredMessage })
              .min(4, messages.phoneInvalidMessage),
            country: z.string({
              required_error: messages.phoneRequiredMessage,
            }),
          },
          {
            invalid_type_error: messages.phoneInvalidMessage,
            required_error: messages.phoneRequiredMessage,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phoneInvalidMessage),
      selection: z
        .object({
          campus: z.string(),
          courseType: z.string(),
          track: z.string().min(2, messages.courseRequiredMessage),
        })
        .superRefine((sel, { addIssue }) => {
          if (sel.courseType === 'in_person' && sel.campus === '') {
            addIssue({
              code: z.ZodIssueCode.custom,
              path: ['campus'],
              message: messages.campusRequiredMessage,
            });
          }
        }),
    })
    .required();

export const ContactForm = (props: Props): ReactElement => {
  const { contactForm, onSuccess, onStep2, ...flexProps } = props;
  const {
    buttonText,
    campuses,
    campusLabel,
    campusPlaceholder,
    courseLabel,
    coursePlaceholder,
    courses,
    courseTypeLabel,
    errorMessage,
    inPersonOption,
    inquiryLabel,
    inquiryPlaceholder,
    legalText,
    newsletterLabel,
    onlineOption,
    phoneLabel,
    phonePlaceholder,
  } = contactForm;
  const {
    params: { language, region },
    pageData: { gaCategory },
  } = usePageContext();
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [websiteCampusPage] = useSessionStorage(
    'campus',
    regionToCampusMap[region][0]
  );
  const [showInquiry, setshowInquiry] = useState(true);

  const formSchema = getSchema(contactForm);

  const formMethods = useForm<FormData>({
    defaultValues: {
      message: '',
      newsletter: false,
      phone: { country: regionToCountry(region) },
      selection: {
        campus: websiteCampusPage,
        courseType: websiteCampusPage === 'rmt' ? 'online' : 'in_person',
        track: '',
      },
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    trigger,
    watch,
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  } | null>();
  const courseType = watch('selection.courseType');
  const formRequest = useFormRequest();
  const [previousPageType] = useSessionStorage<string>('pageTypePrevious');
  const [, setGtmLocalStorage] = useGtmLocalStorage();

  const onSubmit = async (formData: FormData): Promise<void> => {
    setFormFeedback(null);
    const {
      email,
      newsletter,
      phone: { phoneNumber, country },
      selection: { campus: selectedCampus, courseType: selectedType, track },
    } = formData;

    try {
      await formRequest({
        data: {
          ...omit(['newsletter', 'selection'], formData),
          bootcamp: `${track.toUpperCase()}FT`,
          campus: selectedType === 'in_person' ? selectedCampus : 'rmt',
          emailOptIn: newsletter,
          language,
          websiteCampusPage,
        },
        formType: 'contact',
      });

      const formReferrer = sessionStorage.getItem('formReferrer') || 'default';
      const defaultEventCategory =
        previousPageType && previousPageType !== 'undefined'
          ? previousPageType
          : gaCategory;
      const { eventAction, eventCategory = defaultEventCategory } =
        contactFormTags[formReferrer];

      sendEvent({
        campus: selectedCampus,
        course: track,
        email,
        eventAction,
        eventCategory,
        eventLabel: `${selectedCampus}::${track}`,
        pageType: eventCategory,
      });

      setGtmLocalStorage({
        applicationData: {
          campus: selectedCampus,
          course: track,
          phoneNumber,
          ...pick(['email', 'firstName', 'lastName'], formData),
        },
        countryName: country,
      });
      onSuccess();
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    }
  };

  return (
    <Flex direction="column" {...flexProps}>
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" direction="column">
            {step === 1 && (
              <>
                <SelectField
                  error={errors.selection?.track?.message}
                  isRequired
                  label={courseLabel}
                  name="selection.track"
                  options={courses.map((course) => ({
                    key: course.code,
                    text: course.name,
                  }))}
                  placeholder={coursePlaceholder}
                />
                <FormField
                  error=""
                  label={courseTypeLabel}
                  mt={3}
                  name="courseType"
                >
                  <Controller
                    control={control}
                    defaultValue="online"
                    name="selection.courseType"
                    render={({ field: { onChange, value, ref } }) => (
                      <ContentSwitch
                        onChange={onChange}
                        options={[
                          { key: 'online', text: onlineOption },
                          { key: 'in_person', text: inPersonOption },
                        ]}
                        ref={ref}
                        value={value}
                      />
                    )}
                  />
                </FormField>
                {courseType === 'in_person' && (
                  <SelectField
                    error={errors.selection?.campus?.message}
                    isRequired
                    label={campusLabel}
                    mt={4}
                    name="selection.campus"
                    options={campuses.map(({ code, name }) => ({
                      key: code,
                      text: name,
                    }))}
                    placeholder={campusPlaceholder}
                  />
                )}
                <ContactDetailsFields
                  fields={pick(
                    [
                      'chooseBetween',
                      'completeFields',
                      'emailLabel',
                      'emailPlaceholder',
                      'firstNameLabel',
                      'firstNamePlaceholder',
                      'lastNameLabel',
                      'lastNamePlaceholder',
                      'phoneLabel',
                      'phonePlaceholder',
                    ],
                    contactForm
                  )}
                  mt={4}
                  onGoogleClick={() => {
                    setFormFeedback(null);
                    setIsGoogleSubmitting(true);
                  }}
                  onGoogleError={() => {
                    setIsGoogleSubmitting(false);
                    setFormFeedback({
                      status: 'error',
                      message: errorMessage,
                    });
                  }}
                  onGoogleSuccess={async (googleData) => {
                    setIsGoogleSubmitting(false);
                    const isValid = await trigger('selection');
                    if (isValid) {
                      setValue('email', googleData.email);
                      setValue('firstName', googleData.firstName);
                      setValue('lastName', googleData.lastName);
                      clearErrors();
                      if (getValues('message')) {
                        setshowInquiry(false);
                      }
                      setStep(2);
                      onStep2();
                    }
                  }}
                  setFormValues={false}
                  withPhone
                />
              </>
            )}
            {step === 2 && (
              <PhoneField
                error={
                  errors.phone?.message || errors.phone?.phoneNumber?.message
                }
                isRequired
                label={phoneLabel}
                name="phone"
                placeholder={phonePlaceholder}
              />
            )}
            {showInquiry && (
              <TextareaField
                error={errors.message?.message}
                isRequired
                key="message"
                label={inquiryLabel}
                mt={2}
                name="message"
                placeholder={inquiryPlaceholder}
              />
            )}
            <FormSubmit
              buttonText={buttonText}
              isLoading={isSubmitting || isGoogleSubmitting}
              legalText={legalText}
              mt={[2, null, 3]}
              newsletterLabel={newsletterLabel}
              withNewsletter
            />
            {formFeedback?.status && <FormFeedback mt={2} {...formFeedback} />}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
