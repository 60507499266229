import { Box, Flex, HexagonBadge } from '@ironhack/design-system2/components';

import { courseToBackground } from '@/lib/utils';
import type { CourseFormat, CourseTrack } from '@/types';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type Props = {
  format?: CourseFormat | string;
  size: number;
  track: CourseTrack;
} & FlexProps;

export const TrackBadge = (props: Props): ReactElement => {
  const { format, size, track, ...flexProps } = props;
  const badgeColor = courseToBackground[track];
  const hexagonSize = Math.ceil(size / 2) + 0.5;

  return (
    <Flex
      alignItems="center"
      bg={`background.light-${badgeColor}`}
      borderRadius={8}
      h={`${8 * size + 2}px`}
      justifyContent="center"
      w={`${8 * size}px`}
      {...flexProps}
    >
      <Box position="relative">
        <HexagonBadge
          color={`url(#${badgeColor})`}
          fill={`url(#${badgeColor})`}
          size={`${8 * hexagonSize}px`}
        />
        {format === 'pt' && (
          <Box position="absolute" top="0">
            <HexagonBadge
              clipPath="polygon(100% 0%, 50% 0%, 50% 100%, 100% 100%)"
              color="white"
              fill="white"
              opacity="50%"
              size={`${8 * hexagonSize}px`}
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
};
