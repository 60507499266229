import { Flex, Text } from '@ironhack/design-system2/components';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

import { GoogleButton, PhoneField, TextField } from '@/components';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type ContactDetailsFields = {
  chooseBetween?: string;
  completeFields: string;
  emailLabel: string;
  emailPlaceholder: string;
  firstNameLabel: string;
  firstNamePlaceholder: string;
  lastNameLabel: string;
  lastNamePlaceholder: string;
  phoneLabel?: string;
  phonePlaceholder?: string;
};

export type GoogleData = {
  email: string;
  firstName: string;
  lastName: string;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: { phoneNumber: string; country: string };
};

type Props = {
  fields: ContactDetailsFields;
  isSmall?: boolean;
  onGoogleClick: () => void;
  onGoogleError: () => void;
  onGoogleSuccess: (googleData: GoogleData) => void;
  setFormValues?: boolean;
  withPhone?: boolean;
} & FlexProps;

export const ContactDetailsFields = (props: Props): ReactElement => {
  const {
    fields,
    isSmall,
    onGoogleClick,
    onGoogleError,
    onGoogleSuccess,
    setFormValues = true,
    withPhone,
    ...flexProps
  } = props;
  const {
    chooseBetween,
    completeFields,
    emailLabel,
    emailPlaceholder,
    firstNameLabel,
    firstNamePlaceholder,
    lastNameLabel,
    lastNamePlaceholder,
    phoneLabel,
    phonePlaceholder,
  } = fields;

  const {
    formState: { errors },
    setValue,
  } = useFormContext<FormData>();

  const handleGoogleApply = useCallback(
    async (googleCredentials: { credential: string }) => {
      onGoogleClick();
      try {
        const response = await fetch('/api/google-button', {
          body: JSON.stringify({ token: googleCredentials.credential }),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        if (!response.ok) {
          throw new Error('Error validating credentials');
        }
        const responseBody = (await response.json()) as { result: GoogleData };
        const { email, firstName, lastName } = responseBody.result;
        if (setFormValues) {
          setValue('email', email);
          setValue('firstName', firstName);
          setValue('lastName', lastName);
        }
        onGoogleSuccess(responseBody.result);
      } catch {
        onGoogleError();
      }
    },
    [onGoogleSuccess, onGoogleClick, onGoogleError, setValue, setFormValues]
  );

  const sizeStyles = isSmall
    ? {
        completeGap: [5, null, 2],
        fieldGap: 2,
        // Google button's max width is 400px https://developers.google.com/identity/gsi/web/reference/html-reference#data-width
        googleBtn: { alignSelf: 'center', maxWidth: '400px', mt: 0, w: '100%' },
      }
    : {
        completeGap: 4,
        fieldGap: 3,
        googleBtn: { mt: 2 },
      };

  return (
    <Flex direction="column" w="full" {...flexProps}>
      {!isSmall && <Text textStyle="lBold">{chooseBetween}</Text>}
      <GoogleButton
        onCredentialResponse={handleGoogleApply}
        {...sizeStyles.googleBtn}
      />
      <Text mt={sizeStyles.completeGap} textStyle="lBold">
        {completeFields}
      </Text>
      <Flex alignItems="center" direction="column" mt={2}>
        <Flex
          direction={['column', null, 'row']}
          gap={sizeStyles.fieldGap}
          w="full"
        >
          <TextField
            error={errors.firstName?.message}
            isRequired
            label={firstNameLabel}
            name="firstName"
            placeholder={firstNamePlaceholder}
            type="text"
          />
          <TextField
            error={errors.lastName?.message}
            isRequired
            label={lastNameLabel}
            name="lastName"
            placeholder={lastNamePlaceholder}
            type="text"
          />
        </Flex>
        <Flex
          direction={['column', null, 'row']}
          gap={sizeStyles.fieldGap}
          mt={sizeStyles.fieldGap}
          w="full"
        >
          <TextField
            error={errors.email?.message}
            isRequired
            label={emailLabel}
            name="email"
            placeholder={emailPlaceholder}
            type="email"
          />
          {withPhone && (
            <PhoneField
              error={
                errors.phone?.message || errors.phone?.phoneNumber?.message
              }
              isRequired
              label={phoneLabel}
              name="phone"
              placeholder={phonePlaceholder}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
