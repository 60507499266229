import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Flex } from '@ironhack/design-system2/components';
import { useRouter } from 'next/router';
import { isEmpty } from 'ramda';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';

import { SelectField } from '@/components';
import { usePageContext } from '@/hooks';
import type { DatoBlock, DatoCampus, DatoCourse } from '@/lib/datocms';
import { sendEvent } from '@/lib/gtm';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type FormData = {
  campus: string;
  course: string;
};

export type HeroForm = DatoBlock<
  {
    buttonText: string;
    campusPlaceholder: string;
    coursePlaceholder: string;
  },
  'HeroFormRecord'
>;

export type HeroFormData = {
  campuses: Array<Pick<DatoCampus, 'code' | 'name'>>;
  courses: Array<Pick<DatoCourse, 'code' | 'name'>>;
  pathMap: Record<string, string>;
};

type Props = {
  form: HeroForm;
} & FlexProps;

const getSchema = (pathMap: HeroFormData['pathMap']) =>
  z
    .object({
      campus: z.string().length(3),
      course: z.string().length(2),
    })
    .required()
    .refine(({ campus, course }) => pathMap[`${campus}${course}`]);

export const HeroForm = (props: Props): ReactElement => {
  const {
    form: { buttonText, campusPlaceholder, coursePlaceholder },
    ...flexProps
  } = props;

  const {
    pageData: { gaCategory, gaPageType },
  } = usePageContext();

  const {
    pageData: {
      courseSelectorData: { campuses, courses, pathMap },
    },
  } = usePageContext<
    Record<string, never>,
    { courseSelectorData: HeroFormData }
  >();

  const formSchema = getSchema(pathMap);
  const formMethods = useForm<FormData>({
    defaultValues: {
      campus: '',
      course: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    formState: { errors, isSubmitSuccessful, isSubmitted, isSubmitting },
    handleSubmit,
    trigger,
  } = formMethods;

  const router = useRouter();
  const onSubmit = ({ campus, course }: FormData) => {
    sendEvent({
      eventCategory: gaCategory,
      eventAction: 'find your course',
      eventLabel: `${campus}::${course}`,
      pageType: gaPageType,
    });
    const path = pathMap[`${campus}${course}`];
    void router.push(path);
  };

  return (
    <FormProvider {...formMethods}>
      <Flex
        as="form"
        bg="fill.white"
        borderRadius="lg"
        color="text.secondary"
        direction={['column', null, 'row']}
        gap={2}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        padding={2}
        {...flexProps}
      >
        <SelectField
          flex={1}
          isRequired
          name="course"
          onChange={() => trigger()}
          options={courses.map((course) => ({
            key: course.code,
            text: course.name,
          }))}
          placeholder={coursePlaceholder}
        />
        <SelectField
          flex={1}
          isRequired
          name="campus"
          onChange={() => trigger()}
          options={campuses.map((campus) => ({
            key: campus.code,
            text: campus.name,
          }))}
          placeholder={campusPlaceholder}
        />
        <Button
          flex={1}
          isDisabled={isSubmitted && !isEmpty(errors)}
          isLoading={isSubmitting || isSubmitSuccessful}
          px={0}
          type="submit"
        >
          {buttonText}
        </Button>
      </Flex>
    </FormProvider>
  );
};

export const heroFormFragment = `
  fragment heroFormFragment on HeroFormRecord {
    __typename
    buttonText
    campusPlaceholder
    coursePlaceholder
  }
`;
