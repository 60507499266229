import {
  LinkBox,
  LinkOverlay,
  SuperCard,
  Text,
} from '@ironhack/design-system2/components';
import { render } from 'datocms-structured-text-to-plain-text';
import { Image } from 'react-datocms';
import NextLink from 'next/link';
import { is } from 'ramda';

import { Button, StructuredContent, getHref } from '@/components';
import type { DatoCardBlock } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

type Props = { card: DatoCardBlock } & FlexProps;

export const Card = (props: Props): ReactElement => {
  const { card, ...flexProps } = props;
  const {
    button: buttonBlock,
    content,
    contentAsPlainText = false,
    hexagonColor,
    leftBadge: leftBadgeBlock,
    logo,
    note,
    maxContentLines,
    media,
    progress,
    rightBadge: rightBadgeBlock,
    size,
    subtitle,
    title,
    titleMaxLines,
  } = card;

  const button = buttonBlock?.[0];
  const leftBadge = leftBadgeBlock?.[0];
  const rightBadge = rightBadgeBlock?.[0];

  const hasBadges = Boolean(leftBadge || rightBadge || hexagonColor);
  const hasHeader = Boolean(media || hasBadges);
  const BadgesComponent = media ? SuperCard.MediaBadges : SuperCard.Badges;
  const href = getHref(button?.target?.[0]);

  return (
    <SuperCard as={LinkBox} size={size} {...flexProps}>
      {hasHeader && (
        <SuperCard.Header>
          {media && (
            <SuperCard.Media>
              <Image
                data={media.responsiveImage}
                objectFit="cover"
                style={{ height: '160px' }}
              />
            </SuperCard.Media>
          )}
          {hasBadges && (
            <BadgesComponent
              {...(leftBadge && {
                primary: {
                  text: leftBadge.text,
                  colorScheme: leftBadge.background,
                },
              })}
              {...(rightBadge && {
                secondary: {
                  text: rightBadge.text,
                  colorScheme: rightBadge.background,
                },
              })}
              {...(hexagonColor && {
                iconBackground: hexagonColor,
              })}
            />
          )}
        </SuperCard.Header>
      )}
      <SuperCard.Body flexGrow={1}>
        {progress && (
          <SuperCard.Progress
            label={`PROGRESS ${progress}%`}
            value={progress}
          />
        )}
        {button &&
          title &&
          (is(String, href) ? (
            <LinkOverlay href={href} isExternal>
              <SuperCard.Title
                as="h3"
                {...(titleMaxLines && { noOfLines: titleMaxLines })}
              >
                {title}
              </SuperCard.Title>
            </LinkOverlay>
          ) : (
            <NextLink href={href} legacyBehavior passHref>
              <LinkOverlay>
                <SuperCard.Title
                  as="h3"
                  {...(titleMaxLines && { noOfLines: titleMaxLines })}
                >
                  {title}
                </SuperCard.Title>
              </LinkOverlay>
            </NextLink>
          ))}
        {!button && title && (
          <SuperCard.Title
            as="h3"
            {...(titleMaxLines && { noOfLines: titleMaxLines })}
          >
            {title}
          </SuperCard.Title>
        )}
        {subtitle && <SuperCard.Subtitle>{subtitle}</SuperCard.Subtitle>}
        {note && <SuperCard.Note>{note}</SuperCard.Note>}
        {(content || logo) && (
          <SuperCard.Content gap={2}>
            {logo && <Image data={logo.responsiveImage} />}
            {content &&
              (contentAsPlainText ? (
                <Text noOfLines={maxContentLines}>{render(content)}</Text>
              ) : (
                <StructuredContent
                  data={content}
                  {...(maxContentLines && { noOfLines: maxContentLines })}
                />
              ))}
          </SuperCard.Content>
        )}
      </SuperCard.Body>
      {button && (
        <SuperCard.Actions>
          {/* zIndex is needed for the button to be over the LinkBox */}
          <Button size="s" w="full" zIndex={1} {...button} />
        </SuperCard.Actions>
      )}
    </SuperCard>
  );
};
