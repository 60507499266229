import {
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { useToggle, useWindowSize } from 'react-use';
import {
  ArrowLeftCircle,
  ArrowRightCircle,
} from '@ironhack/design-system2/icons';

import { StoryPlayerDesktop, StoryPlayerMobile } from '@/components';
import type { DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';

type Props = {
  onExit: () => void;
  onNext: () => void | null;
  onPrevious: () => void | null;
  story: DatoStory;
};

export const StoryPlayer = (props: Props): ReactElement => {
  const { onExit, onNext, onPrevious } = props;
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [showModal, toggleShowModal] = useToggle(true);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const StoryPlayerComponent = isMobile
    ? StoryPlayerMobile
    : StoryPlayerDesktop;

  const handleOnClose = () => {
    toggleShowModal();
    onExit();
  };

  return (
    <Modal isOpen={showModal} onClose={handleOnClose}>
      <ModalContent
        bg="black"
        h={windowHeight}
        m={0}
        maxH="none"
        maxW="none"
        rounded="none"
        w={windowWidth}
      >
        {!isMobile && <ModalCloseButton color="white" />}
        <ModalBody
          alignItems="center"
          display="flex"
          gap={3}
          justifyContent="center"
          p={0}
        >
          {!isMobile && (
            <Icon
              as={ArrowLeftCircle}
              boxSize="32px"
              color={onPrevious ? 'white' : 'stroke.medium'}
              cursor={onPrevious ? 'pointer' : 'not-allowed'}
              onClick={onPrevious}
            />
          )}
          <StoryPlayerComponent {...props} />
          {!isMobile && (
            <Icon
              as={ArrowRightCircle}
              boxSize="32px"
              color={onNext ? 'white' : 'stroke.medium'}
              cursor={onNext ? 'pointer' : 'not-allowed'}
              onClick={onNext}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
