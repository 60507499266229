import {
  Box,
  Button as DsButton,
  Flex,
  HexagonBadge,
  Icon,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { ArrowRight, Check } from '@ironhack/design-system2/icons';
import { ReactElement, useEffect, useRef, useState } from 'react';

import type { DatoBlock, DatoButtonBlock } from '@/lib/datocms';
import { Button, ModuleWrapper } from '@/components';
import type { WebCohort } from '@/types';
import { getPrettyCohort } from '@/lib/utils';
import { usePageContext } from '@/hooks';

import type { FlexProps } from '@ironhack/design-system2/components';

export type CohortSelection = DatoBlock<{
  title: string;
  text: string;
  selectedButton?: [DatoButtonBlock];
  unselectedButtonText: string;
}>;

type Props = CohortSelection & {
  cohort?: WebCohort;
  onUnselectClick: () => void;
  onSelectedClick?: () => void;
  isAnimating?: boolean;
} & FlexProps;

const courseToBackground = {
  wd: 'web',
  da: 'data',
  cy: 'cyber',
  ux: 'ux',
};

export const CohortSelection = (props: Props): ReactElement => {
  const {
    cohort,
    onUnselectClick,
    onSelectedClick,
    isAnimating,
    selectedButton: [selectedButton] = [],
    text,
    title,
    unselectedButtonText,
    ...flexProps
  } = props;
  const {
    pageData: { experimentId, variantId },
    params: { language },
  } = usePageContext<
    Record<string, never>,
    { experimentId: string; variantId: string }
  >();
  const iconSize = useBreakpointValue({ base: '24px', lg: '48px' });

  const selectedCohortIconBackground = `url(#${
    courseToBackground[cohort?.track as keyof typeof courseToBackground]
  })`;

  const salesforceId = cohort?.external_ids.salesforce || '';
  const buttonTarget = {
    route: {
      path: `${selectedButton?.target[0].route?.path || ''}/c/${salesforceId}`,
    },
  };

  const prettyCohort = cohort && getPrettyCohort(cohort, language);

  const [isAnimatingButton, setIsAnimatingButton] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const animationBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    animationBoxRef.current?.addEventListener('transitionstart', () => {
      if (isFirstRender) setIsFirstRender(false);
      setIsAnimatingButton(false);
    });
  }, [isFirstRender]);

  return (
    <ModuleWrapper
      innerProps={{ px: [0, null, 7, 10] }}
      moduleFormat={{
        spacingBottom: '0',
        spacingTop: '0',
        background: 'transparent',
      }}
      outerProps={{
        bottom: [0, null, '3rem'],
        position: 'fixed',
        w: 'full',
        zIndex: 'overlay',
      }}
    >
      <Flex
        alignItems={[null, null, 'center']}
        bg="background.white"
        borderRadius={[null, null, 'lg']}
        boxShadow="0px -4px 24px rgba(0, 0, 0, 0.25)"
        direction={['column', null, 'row']}
        gap={2}
        justifyContent={[null, null, 'space-between']}
        position="relative"
        px={[2, null, 3]}
        py={[2, null, 1]}
        w="full"
        {...flexProps}
      >
        {!cohort && (
          <>
            <Flex direction="column" gap={0.5}>
              <Text color="text.primary" textStyle={['xlBold', null, '2xl']}>
                {title}
              </Text>
              <Text color="text.secondary" noOfLines={1} textStyle="l">
                {text}
              </Text>
            </Flex>
            <DsButton
              onClick={onUnselectClick}
              w={['100%', null, 'fit-content']}
            >
              {unselectedButtonText}
            </DsButton>
          </>
        )}
        {prettyCohort && (
          <>
            <Flex alignItems="center">
              <HexagonBadge
                color={selectedCohortIconBackground}
                fill={selectedCohortIconBackground}
                size={iconSize}
                style={{ minHeight: '48px', minWidth: '48px' }}
              />
              <Flex direction="column" gap={[0, null, null]} ml={[2, null, 3]}>
                <Text color="text.primary" textStyle={['xlBold', null, '2xl']}>
                  {prettyCohort.track}
                </Text>
                <Text color="text.secondary" noOfLines={1} textStyle="l">
                  {`(${prettyCohort.startDate} - ${prettyCohort.endDate}) ${prettyCohort.format} - ${prettyCohort.modality} - ${prettyCohort.language}`}
                </Text>
              </Flex>
            </Flex>
            <Button
              {...selectedButton}
              onClick={onSelectedClick}
              opacity={[null, null, isFirstRender || isAnimatingButton ? 1 : 0]}
              rel="nofollow"
              /* @ts-expect-error - Couldn't find a way to make the types work together */
              rightIcon={ArrowRight}
              target={[buttonTarget]}
              {...(experimentId && {
                tracking: {
                  f: `application:::valueProp:::${salesforceId}:::${experimentId}~${variantId}`,
                },
              })}
              transform={[
                null,
                null,
                isFirstRender || isAnimatingButton
                  ? 'translateX(0)'
                  : 'translateX(-8px)',
              ]}
              transition="opacity 400ms ease-out, transform 400ms ease-out"
              w={['100%', null, 'fit-content']}
            />
          </>
        )}

        <Box
          alignItems="center"
          bg="support.success.icon"
          borderRadius="lg"
          display={['none', null, 'flex']}
          h="70px"
          justifyContent="center"
          left="0"
          onTransitionEnd={() => setIsAnimatingButton(true)}
          opacity={[null, null, isAnimating ? '1' : '0']}
          pointerEvents="none"
          position="absolute"
          ref={animationBoxRef}
          transition="opacity 400ms ease-out"
          w="full"
        >
          <Icon as={Check} boxSize="40px" color="white" />
        </Box>
      </Flex>
    </ModuleWrapper>
  );
};

export const cohortSelectionFragment = `
  fragment cohortSelectionFragment on CohortSelectionRecord {
    __typename
    title
    text
    selectedButton {
      ...buttonFragment
    }
    unselectedButtonText
  }
`;
