import { Card, Progress, Text } from '@ironhack/design-system2/components';

import { StructuredContent } from '../structured-content';
import type { DatoBlock } from '@/lib/datocms';

import type { StructuredTextGraphQlResponse } from 'react-datocms';
import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';
import type { BackgroundColor } from '@ironhack/design-system2';

export type ProgressCard = DatoBlock<{
  content: StructuredTextGraphQlResponse;
  progressFill: BackgroundColor;
  progressLabel: string;
  progressValue: number;
  tabTitle: string;
  title: string;
}>;

type Props = {
  card: ProgressCard;
} & FlexProps;

export const ProgressCard = (props: Props): ReactElement => {
  const { card, ...flexProps } = props;
  const { content, progressFill, progressLabel, progressValue, title } = card;

  return (
    <Card p={[2, null, 5]} {...flexProps}>
      <Text color="text.secondary" textStyle="xsBold">
        {`${progressLabel} ${progressValue}%`}
      </Text>
      <Progress mt={1} value={progressValue} variant={progressFill} />
      <Text color="text.primary" mt={2} textStyle="2xl">
        {title}
      </Text>
      <StructuredContent data={content} mt={1} />
    </Card>
  );
};

export const progressCardFragment = `
  fragment progressCardFragment on ProgressCardRecord {
    __typename
    content {
      value
    }
    progressFill
    progressLabel
    tabTitle
    title
  }
`;
