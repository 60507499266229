import { ReactElement } from 'react';
import { FormField, PhoneInput } from '@ironhack/design-system2/components';
import { Controller, useFormContext } from 'react-hook-form';

import { usePageContext } from '@/hooks';

import type { FormControlProps } from '@ironhack/design-system2/components';

type Phone = { phoneNumber: string; country: string };

type Props = {
  label: string;
  error?: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
} & FormControlProps;

export const PhoneField = (props: Props): ReactElement => {
  const { label, error, name, placeholder, isRequired, ...formFieldProps } =
    props;
  const { control } = useFormContext();
  const {
    params: { language },
  } = usePageContext();
  return (
    <FormField
      error={error}
      isInvalid={Boolean(error)}
      isRequired
      label={label}
      name={name}
      {...formFieldProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, onChange, value, ref } }) => (
          <PhoneInput
            language={language}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            ref={ref}
            value={value as Phone}
          />
        )}
        rules={{ required: isRequired }}
      />
    </FormField>
  );
};
