import { ReactElement } from 'react';
import { FormField, Input } from '@ironhack/design-system2/components';
import { useFormContext } from 'react-hook-form';

import type { FormControlProps } from '@ironhack/design-system2/components';

type Props = {
  label: string;
  error?: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
  type?: string;
} & FormControlProps;

export const TextField = (props: Props): ReactElement => {
  const {
    label,
    error,
    name,
    placeholder,
    isRequired,
    type = 'text',
    ...formFieldProps
  } = props;
  const { register } = useFormContext();

  return (
    <FormField
      error={error}
      isInvalid={Boolean(error)}
      isRequired={isRequired}
      label={label}
      name={name}
      {...formFieldProps}
    >
      <Input
        placeholder={placeholder}
        type={type}
        {...register(name, { required: isRequired })}
      />
    </FormField>
  );
};
