import {
  Flex,
  Icon,
  Logo,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@ironhack/design-system2/components';
import { ChevronLeft, Menu, X } from '@ironhack/design-system2/icons';
import { anyPass, isEmpty, isNil, omit } from 'ramda';
import { useToggle } from 'react-use';

import {
  Button,
  LanguageSelector,
  Link,
  MenuItem,
  ModuleWrapper,
} from '@/components';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import type { Navbar } from '@/modules';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

type Props = {
  data: Navbar;
  onCloseMenu: () => void;
  onFirstLevelClick: (index: number) => () => void;
  onSecondLevelClick: (index: number) => () => void;
  menuSelection: number[];
} & FlexProps;

const getMenuSeoLinks = (menuItem: MenuItem): Array<[string, string]> =>
  menuItem.target?.[0]
    ? [
        [
          menuItem.text,
          menuItem.target[0].link || menuItem.target[0].route.path,
        ],
      ]
    : menuItem.children.flatMap(getMenuSeoLinks);

export const NavbarMobile = (props: Props): ReactElement => {
  const {
    data,
    menuSelection,
    onCloseMenu,
    onFirstLevelClick,
    onSecondLevelClick,
    ...flexProps
  } = props;
  const {
    button: [button],
    logoTarget: [logoTarget],
    menu,
  } = data;
  const {
    pageData: { gaPageType },
  } = usePageContext();

  const [showMenu, toggleMenu] = useToggle(false);
  const firstMenuItem = menu.children?.[menuSelection[0]];
  const secondMenuItem =
    menu.children?.[menuSelection[0]]?.children?.[menuSelection[1]];

  const handleBackClick = () =>
    secondMenuItem
      ? onSecondLevelClick(menuSelection[1])()
      : firstMenuItem
      ? onFirstLevelClick(menuSelection[0])()
      : toggleMenu();

  const handleCloseMenu = () => {
    onCloseMenu();
    toggleMenu();
  };

  const headerTitle = secondMenuItem
    ? secondMenuItem.text
    : firstMenuItem
    ? firstMenuItem.text
    : '';

  // For SEO
  const allMenuLinks = getMenuSeoLinks(menu);

  return (
    <>
      <ModuleWrapper
        innerProps={{
          alignItems: 'center',
          display: ['flex', null, null, 'none'],
          justifyContent: 'space-between',
          ...flexProps,
        }}
        moduleFormat={{
          background: 'transparent',
          spacingTop: '16',
          spacingBottom: '16',
        }}
      >
        <Link {...logoTarget} aria-label="Ironhack Home Page">
          <Logo
            colorScheme={flexProps.color === 'text.inverse' ? 'light' : 'dark'}
            height={['20px', null, '25px']}
            layout="full"
            width={['125px', null, '157px']}
          />
        </Link>
        <Flex alignItems="center" gap={3}>
          <LanguageSelector />
          <Icon as={Menu} boxSize="24px" onClick={toggleMenu} />
        </Flex>
        <ul style={{ display: 'none' }}>
          {/* Adds a hidden entry for all the menu links for SEO purposes */}
          {allMenuLinks.map(([text, href], index) => (
            <li key={`${text}-${index}`}>
              <a href={href}>{text}</a>
            </li>
          ))}
        </ul>
      </ModuleWrapper>
      <Modal isOpen={showMenu} onClose={handleCloseMenu} size="full">
        <ModalContent
          bg="linear-gradient(166.18deg, #3800EB 67.94%, #4CC9F0 100%);"
          color="text.inverse"
          margin={0}
          rounded="none"
        >
          <ModalHeader
            alignItems="center"
            as={Flex}
            justifyContent={headerTitle ? 'space-between' : 'flex-end'}
            pt={3}
            px={2}
          >
            {headerTitle && (
              <>
                <Icon
                  as={ChevronLeft}
                  boxSize="24px"
                  onClick={handleBackClick}
                />
                <Text margin="auto" textStyle="lBold">
                  {headerTitle}
                </Text>
              </>
            )}
            <Icon as={X} boxSize="24px" onClick={handleCloseMenu} />
          </ModalHeader>
          <ModalBody
            as={Flex}
            direction="column"
            justifyContent="space-between"
            px={2}
            py={0}
          >
            <nav>
              <Flex as="ol" direction="column" gap={3}>
                {!firstMenuItem && (
                  <>
                    {menu.children.map(
                      ({ eventLabel, ...menuItem }, topIndex) => (
                        <Flex as="li" key={topIndex}>
                          <MenuItem
                            {...menuItem}
                            level={1}
                            onClick={() => {
                              if (anyPass([isEmpty, isNil])(menuItem.target)) {
                                sendEvent({
                                  eventAction: 'nav menu_level 1',
                                  eventCategory: 'Nav',
                                  eventLabel,
                                  pageType: gaPageType,
                                });
                              }

                              if (menuItem.children.length > 0) {
                                onFirstLevelClick(topIndex)();
                              } else {
                                handleCloseMenu();
                              }
                            }}
                          />
                        </Flex>
                      )
                    )}
                  </>
                )}
                {firstMenuItem && !secondMenuItem && (
                  <>
                    {firstMenuItem.children.map(
                      ({ eventLabel, ...midMenuItem }, midIndex) => (
                        <Flex as="li" key={`${menuSelection[0]}-${midIndex}`}>
                          <MenuItem
                            {...midMenuItem}
                            level={2}
                            onClick={() => {
                              if (
                                anyPass([isEmpty, isNil])(midMenuItem.target)
                              ) {
                                sendEvent({
                                  eventAction: 'nav menu_level 2',
                                  eventCategory: 'Nav',
                                  eventLabel: `${firstMenuItem.eventLabel}::${eventLabel}`,
                                  pageType: gaPageType,
                                });
                              }

                              if (midMenuItem.children.length > 0) {
                                onSecondLevelClick(midIndex)();
                              } else {
                                handleCloseMenu();
                              }
                            }}
                          />
                        </Flex>
                      )
                    )}
                  </>
                )}
                {secondMenuItem && (
                  <>
                    {secondMenuItem.children.map((leafMenuItem, leafIndex) => (
                      <Flex
                        as="li"
                        key={`${menuSelection[0]}-${menuSelection[1]}-${leafIndex}}`}
                        w="full"
                      >
                        <MenuItem
                          {...omit(['children'], leafMenuItem)}
                          isCompact
                          level={3}
                          onClick={handleCloseMenu}
                        />
                      </Flex>
                    ))}
                  </>
                )}
              </Flex>
            </nav>
            <Button tracking={{ f: 'nb' }} {...button} mt={2} w="full" />
          </ModalBody>
          <ModalFooter justifyContent="flex-start" pt={5} px={2} py={3}>
            <Link {...logoTarget}>
              <Logo
                colorScheme="light"
                height="14px"
                layout="full"
                width="92px"
              />
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
