import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { getLanguageName } from '@/lib/utils';
import type { Language } from '@/types';

export const useLanguageNames = (
  languages: Language[],
  targetLanguage: Language
) => {
  const [languageNames, setLanguageNames] =
    useState<Record<Language, string>>();

  useEffectOnce(() => {
    const languageMap = Object.fromEntries(
      languages.map((language) => [
        language,
        getLanguageName(language, targetLanguage),
      ])
    ) as Record<Language, string>;
    setLanguageNames(languageMap);
  });

  return languageNames;
};
