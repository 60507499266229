import {
  __,
  add,
  adjust,
  curry,
  join,
  last,
  map,
  mathMod,
  multiply,
  pipe,
  split,
} from 'ramda';

const getTimeZoneDate = (date: Date, timeZone: string): Date =>
  new Date(date.toLocaleString('en-US', { timeZone }));

export const getCohortTime = curry(
  (
    sourceTimezone: string,
    targetTimezone: string,
    date: string,
    time: string
  ) => {
    const d = new Date(date);
    let sourceDate: Date, targetDate: Date;
    try {
      sourceDate = getTimeZoneDate(d, sourceTimezone);
    } catch {
      sourceDate = getTimeZoneDate(d, 'UTC');
    }
    try {
      targetDate = getTimeZoneDate(d, targetTimezone);
    } catch {
      targetDate = getTimeZoneDate(d, 'UTC');
    }

    const hoursDiff =
      (targetDate.getTime() - sourceDate.getTime()) / 60 / 60 / 1000;
    const sign = Math.sign(hoursDiff);
    const hourDiff = pipe(Math.abs, Math.floor, multiply(sign))(hoursDiff);
    const minuteDiff = Number.isInteger(hoursDiff) ? 0 : sign * 30;
    return pipe(
      split(':'),
      map((s) => Number.parseInt(s)),
      adjust(0, pipe(add(hourDiff), mathMod(__, 24))),
      adjust(1, pipe(add(minuteDiff), mathMod(__, 60))),
      map(
        pipe(
          (n) => n.toString(),
          (s) => s.padStart(2, '0')
        )
      ),
      join(':')
    )(time);
  }
);

export const getTimezoneAbbreviation = (timezone: string, locale: string) => {
  const d = new Date();
  let date: string;

  try {
    date = d.toLocaleTimeString(locale, {
      timeZone: timezone,
      timeZoneName: 'short',
    });
  } catch {
    date = d.toLocaleTimeString(locale, {
      timeZone: 'UTC',
      timeZoneName: 'short',
    });
  }

  return last(date.split(' ')) as string;
};
