import { useRouter } from 'next/router';
import { Flex, Icon, Text, chakra } from '@ironhack/design-system2/components';
import { Globe } from '@ironhack/design-system2/icons';
import { useMeasure } from 'react-use';
import { pluck } from 'ramda';

import type { Language } from '@/types';
import { usePageContext } from '@/hooks';
import { getLanguageName } from '@/lib/utils';
import { useLanguageNames } from 'hooks/use-language-names';

import type { ChangeEvent, ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';

type Props = FlexProps;

export const LanguageSelector = (props: Props): ReactElement => {
  const { ...flexProps } = props;
  const router = useRouter();
  const {
    params: { language },
    pageData: { languageOptions },
  } = usePageContext();
  const [ref, { width, height, top, left }] = useMeasure();

  const handleLanguageSelected = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value as Language;
    const { path } = languageOptions.find((l) => l.language === newLanguage);
    void router.push(path);
  };

  const size = {
    w: width > 0 ? `${width}px` : null,
    h: height > 0 ? `${height}px` : null,
  };
  const position = { top, left };
  const languageNames = useLanguageNames(
    pluck('language', languageOptions),
    language
  );

  // Omit the selector id there's only one language option
  if (languageOptions.length === 1) return null;

  return (
    <Flex position="relative" {...size} {...flexProps}>
      <chakra.select
        cursor="pointer"
        onChange={handleLanguageSelected}
        opacity={0}
        pos="absolute"
        value={language}
        zIndex={1}
        {...size}
        {...position}
      >
        {Object.entries(languageNames || {}).map(
          ([languageCode, languageName]) => (
            <option key={languageCode} value={languageCode}>
              {languageName}
            </option>
          )
        )}
      </chakra.select>
      <Flex
        alignItems="center"
        as="button"
        cursor="pointer"
        gap={0.5}
        h="min-content"
        ref={ref}
        type="button"
        w="min-content"
        {...flexProps}
      >
        <Icon as={Globe} boxSize="24px" />
        <Text textStyle="l" textTransform="uppercase">
          {language}
        </Text>
      </Flex>
    </Flex>
  );
};
