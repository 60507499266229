import Script from 'next/script';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { Box } from '@ironhack/design-system2/components';

import config from '@/config';
import { usePageContext } from '@/hooks';

import type { BoxProps } from '@ironhack/design-system2/components';

type GoogleCredentialResponse = {
  credential: string;
};

type Props = {
  onCredentialResponse: (response: GoogleCredentialResponse) => void;
} & BoxProps;

export const GoogleButton = (props: Props): ReactElement => {
  const { onCredentialResponse, ...boxProps } = props;
  const googleButtonRef = useRef<HTMLDivElement>();
  const { locale } = usePageContext();

  const handleScriptLoaded = useCallback(() => {
    window.google.accounts.id.initialize({
      client_id: config.google.clientId,
      callback: onCredentialResponse,
    });
    window.google.accounts.id.renderButton(googleButtonRef.current, {
      locale,
      shape: 'pill',
      size: 'large',
      text: 'continue_with',
      theme: 'outline',
      width: googleButtonRef.current.clientWidth,
    });
  }, [locale, onCredentialResponse]);

  useEffect(() => {
    const isScriptLoaded = Boolean(window.google);
    const isButtonRendered = googleButtonRef.current.children.length > 0;
    if (isScriptLoaded && !isButtonRendered) {
      handleScriptLoaded();
    }
  }, [handleScriptLoaded]);

  return (
    <>
      <Script
        onLoad={handleScriptLoaded}
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
      />
      <Box ref={googleButtonRef} w={['full', null, '288px']} {...boxProps} />
    </>
  );
};
