import {
  Flex,
  List,
  ListIcon,
  ListItem,
} from '@ironhack/design-system2/components';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';

import { toUpperPascalCase } from '@/lib/utils';
import type { DatoListWithIconsBlock } from '@/lib/datocms';

import type {
  IconProps as ChakraIconProps,
  ListProps,
} from '@ironhack/design-system2';
import type { IconProps } from '@ironhack/design-system2/icons';
import type { ComponentType, ReactElement } from 'react';

type Props = {
  iconProps?: IconProps & ChakraIconProps;
  list: DatoListWithIconsBlock;
} & ListProps;

export const ListWithIcons = (props: Props): ReactElement => {
  const {
    iconProps = {},
    list: { listItems },
    ...listProps
  } = props;

  const iconMap = useMemo<Record<string, ComponentType<IconProps>>>(
    () =>
      listItems
        .map((listItem) => listItem.icon?.[0]?.name)
        .filter(Boolean)
        .reduce<Record<string, ComponentType<IconProps>>>((acc, name) => {
          if (acc.name) return acc;
          return {
            ...acc,
            // @ts-expect-error -- I don't know how to type this. Feel free to try.
            [name]: dynamic(() =>
              import('@ironhack/design-system2/icons').then(
                (m) => m[toUpperPascalCase(name)]
              )
            ),
          };
        }, {}),
    [listItems]
  );

  return (
    <List spacing={2} {...listProps}>
      {listItems.map(({ text, icon: [iconBlock] }) => {
        const Icon = iconBlock && iconMap[iconBlock.name];
        const color = iconBlock?.color && `icon.${iconBlock.color}`;
        return (
          <ListItem alignItems="center" key={text}>
            <Flex alignItems="center">
              {Icon && (
                <ListIcon
                  as={Icon}
                  boxSize="24px"
                  color={color}
                  {...iconProps}
                />
              )}
              {text}
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};
