import {
  Box,
  Flex,
  ListItem,
  Logo,
  SimpleGrid,
  UnorderedList,
} from '@ironhack/design-system2/components';
import { anyPass, isEmpty, isNil, omit } from 'ramda';
import { useRef } from 'react';
import { useClickAway } from 'react-use';

import {
  Button,
  LanguageSelector,
  Link,
  MenuItem,
  ModuleWrapper,
} from '@/components';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import type { Navbar } from '@/modules';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';

type Props = {
  data: Navbar;
  onCloseMenu: () => void;
  onFirstLevelClick: (index: number) => () => void;
  onSecondLevelClick: (index: number) => () => void;
  menuSelection: number[];
} & FlexProps;

const getMenuSeoLinks = (menuItem: MenuItem): Array<[string, string]> =>
  menuItem.target?.[0]
    ? [
        [
          menuItem.text,
          menuItem.target[0].link || menuItem.target[0].route.path,
        ],
      ]
    : menuItem.children.flatMap(getMenuSeoLinks);

export const NavbarDesktop = (props: Props): ReactElement => {
  const {
    data,
    menuSelection,
    onCloseMenu,
    onFirstLevelClick,
    onSecondLevelClick,
    ...flexProps
  } = props;
  const {
    button: [button],
    logoTarget: [logoTarget],
    menu,
  } = data;
  const {
    pageData: { gaPageType },
  } = usePageContext();

  // Close the expanded menu when clicking away from the navbar
  const navbarRef = useRef(null);
  useClickAway(navbarRef, onCloseMenu);

  const firstMenuItem = menu.children?.[menuSelection[0]];
  const secondMenuItem =
    menu.children?.[menuSelection[0]]?.children?.[menuSelection[1]];

  // For SEO
  const allMenuLinks = getMenuSeoLinks(menu);

  return (
    <Box
      display={['none', null, null, 'block']}
      position="relative"
      ref={navbarRef}
    >
      <ModuleWrapper
        innerProps={{
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 3,
        }}
        moduleFormat={{
          background: 'transparent',
          spacingTop: '32',
          spacingBottom: '32',
        }}
        outerProps={flexProps}
      >
        <Link {...logoTarget} aria-label="Ironhack Home Page">
          <Logo
            colorScheme={flexProps.color === 'text.inverse' ? 'light' : 'dark'}
            height="20px"
            layout="full"
            width="125px"
          />
        </Link>
        <nav>
          <Flex as="ul" gap={2}>
            {menu.children.map(({ eventLabel, ...menuItem }, topIndex) => {
              const menuItemProps = {
                isSelected: topIndex === menuSelection[0],
                level: 1 as const,
              };
              return (
                <Flex as="li" key={topIndex}>
                  <MenuItem
                    {...menuItem}
                    {...menuItemProps}
                    onClick={() => {
                      if (anyPass([isEmpty, isNil])(menuItem.target)) {
                        sendEvent({
                          eventAction: 'nav menu_level 1',
                          eventCategory: 'Nav',
                          eventLabel,
                          pageType: gaPageType,
                        });
                      }

                      if (menuItem.children.length > 0) {
                        onFirstLevelClick(topIndex)();
                      } else {
                        onCloseMenu();
                      }
                    }}
                  />
                </Flex>
              );
            })}
            {/* Adds a hidden entry for all the menu links for SEO purposes */}
            {allMenuLinks.map(([text, href], index) => (
              <li key={`${text}-${index}`} style={{ display: 'none' }}>
                <a href={href}>{text}</a>
              </li>
            ))}
          </Flex>
        </nav>
        <Flex alignItems="center" gap={3}>
          <LanguageSelector />
          <Button tracking={{ f: 'nb' }} {...button} size="s" />
        </Flex>
      </ModuleWrapper>
      <ModuleWrapper
        innerProps={{ direction: 'column' }}
        moduleFormat={{
          background: 'transparent',
          spacingTop: '40',
          spacingBottom: '64',
        }}
        outerProps={{
          ...flexProps,
          backdropFilter: 'blur(15px) brightness(50%)',
          display: menuSelection.length > 0 ? 'inherit' : 'none',
          position: 'absolute',
          zIndex: 10,
        }}
      >
        {firstMenuItem && (
          <Flex color="text.inverse" gap={8}>
            <UnorderedList ml={0} spacing={2} styleType="none">
              {firstMenuItem.children.map(
                ({ eventLabel, ...midMenuItem }, midIndex) => {
                  const menuItemProps = {
                    isSelected: midIndex === menuSelection[1],
                    level: 2 as const,
                  };

                  return (
                    <ListItem key={`${menuSelection[0]}-${midIndex}`} w="237px">
                      <MenuItem
                        {...midMenuItem}
                        {...menuItemProps}
                        onClick={() => {
                          if (anyPass([isEmpty, isNil])(midMenuItem.target)) {
                            sendEvent({
                              eventAction: 'nav menu_level 2',
                              eventCategory: 'Nav',
                              eventLabel: `${firstMenuItem.eventLabel}::${eventLabel}`,
                              pageType: gaPageType,
                            });
                          }

                          if (midMenuItem.children.length > 0) {
                            onSecondLevelClick(midIndex)();
                          } else {
                            onCloseMenu();
                          }
                        }}
                      />
                    </ListItem>
                  );
                }
              )}
            </UnorderedList>
            {secondMenuItem && (
              <SimpleGrid as="ul" columns={3} spacing={3} w="full">
                {secondMenuItem.children.map((leafMenuItem, leafIndex) => {
                  const isCompact = secondMenuItem.children.length > 3;
                  const menuItemProps = {
                    isCompact,
                    isSelected: false,
                    level: 3 as const,
                  };
                  return (
                    <Flex
                      as="li"
                      key={`${menuSelection[0]}-${menuSelection[1]}-${leafIndex}}`}
                      w="full"
                    >
                      <MenuItem
                        {...omit(['children'], leafMenuItem)}
                        {...menuItemProps}
                        direction={isCompact ? 'row' : 'column'}
                        onClick={onCloseMenu}
                      />
                    </Flex>
                  );
                })}
              </SimpleGrid>
            )}
          </Flex>
        )}
      </ModuleWrapper>
    </Box>
  );
};
