import { Box, Flex, Icon, Text } from '@ironhack/design-system2/components';
import {
  Calendar,
  Clock,
  Edit3,
  Flag,
  Home,
  Play,
  Wifi,
} from '@ironhack/design-system2/icons';
import { nextThursday, nextTuesday } from 'date-fns';

import { Button } from '@/components';
import { realToFakeSalesforceIds, usePageContext } from '@/hooks';
import { commonKeys, getPrettyCohort } from '@/lib/utils';
import { hackCohortFor20Weeks } from '@/lib/utils/experiments';
import type { ComparisonCohortSelector } from '@/modules';
import type { WebCohort } from '@/types';

import type { ReactElement } from 'react';

type Props = {
  buttonBasePath: string;
  buttonText: string;
  cohort: WebCohort;
  newCourseFormat: ComparisonCohortSelector['newCourseFormat'];
};

export const ComparisonCohortCard = (props: Props): ReactElement => {
  const { buttonBasePath, buttonText, newCourseFormat } = props;
  let { cohort } = props;
  const salesforceId = cohort.external_ids.salesforce;

  const today = new Date();
  const newFormatIds = Object.keys(realToFakeSalesforceIds);

  const {
    pageData: { experimentId, variantId },
    params: { language },
  } = usePageContext<
    Record<string, never>,
    { experimentId: string; variantId: string }
  >();

  const dayFormatter = new Intl.DateTimeFormat([language], {
    weekday: 'short',
  });

  const isRemote = cohort.campus === 'rmt';
  const isPartTime = cohort.format === 'pt';
  const isNewFormat = newCourseFormat && newFormatIds.includes(salesforceId);

  if (isNewFormat) {
    cohort = hackCohortFor20Weeks(cohort);
  }
  const prettyCohort = getPrettyCohort(cohort, language);

  return (
    <Box boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)" px={3}>
      <Flex
        borderColor="stroke.light"
        borderTop="1px"
        columnGap={1}
        justify="space-between"
        py={2}
        rowGap={[1.5, 2, 1.5, 2]}
        wrap="wrap"
      >
        <Flex
          align="center"
          h={['auto', '38px', 'auto', '38px']}
          justify={['space-between', 'start', 'space-between', 'start']}
          mb={[0, -1, 0, -1]}
          w={['full', 'auto', 'full', 'auto']}
        >
          <Text textStyle="lBold" w={['auto', '144px', 'auto', '144px']}>
            {`${prettyCohort.startDate} - ${prettyCohort.endDate}`}
          </Text>
          <Flex as="p" gap={0.5}>
            {prettyCohort.discountPrice}
            {prettyCohort.price !== prettyCohort.discountPrice && (
              <Text
                as="span"
                color="text.tertiary"
                textDecoration="line-through"
              >
                {prettyCohort.price}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex
          color="text.secondary"
          direction="column"
          gap={[1.5, 2, 1.5, 2]}
          order={[0, 1, 0, 1]}
          w="full"
        >
          <Flex gap={[1.5, 2, 1.5, 2]} wrap="wrap">
            {newCourseFormat && (
              <Flex
                align="center"
                as="p"
                {...(isNewFormat && { color: 'link.link' })}
                gap={0.5}
                w={['full', 'auto', 'full', 'auto']}
              >
                <Icon as={Calendar} boxSize="20px" />
                {isNewFormat
                  ? newCourseFormat.newFormatText
                  : `${isPartTime ? 24 : 9} ${commonKeys[language].weeks}`}
              </Flex>
            )}
            <Flex align="center" as="p" gap={0.5}>
              <Icon as={isRemote ? Wifi : Home} boxSize="20px" />
              {isRemote ? 'Online' : prettyCohort.campus}
            </Flex>
            <Flex align="center" as="p" gap={0.5}>
              <Icon as={Flag} boxSize="20px" />
              {prettyCohort.language}
            </Flex>
          </Flex>

          {!isNewFormat ? (
            <Flex align="center" as="p" gap={0.5}>
              <Icon alignSelf="start" as={Clock} boxSize="20px" mt="2px" />
              {prettyCohort.weekDays.join(isPartTime ? ' & ' : ' - ')}
              {` | ${prettyCohort.weekDayStartTime}`}
              {` - ${prettyCohort.weekDayEndTime}`}
              {isPartTime &&
                `
                ${prettyCohort.weekendDays.join(' & ')}
                | ${prettyCohort.weekendStartTime}
                - ${prettyCohort.weekendEndTime}
              `}
              {` (${prettyCohort.timezone})`}
            </Flex>
          ) : (
            <>
              <Flex align="center" as="p" gap={0.5}>
                <Icon alignSelf="start" as={Play} boxSize="20px" mt="2px" />
                {newCourseFormat.liveClassesText}
                {`: ${prettyCohort.weekDays.join(' & ')}`}
                {` | ${prettyCohort.weekDayStartTime}`}
                {` - ${prettyCohort.weekDayEndTime}`}
                {` ${prettyCohort.weekendDays.join(' & ')}`}
                {` | ${prettyCohort.weekendStartTime}`}
                {` - ${prettyCohort.weekendEndTime}`}
                {` (${prettyCohort.timezone})`}
              </Flex>
              <Flex align="center" as="p" gap={0.5}>
                <Icon alignSelf="start" as={Edit3} boxSize="20px" mt="2px" />
                {newCourseFormat.selfGuidedText}
                {`: ${[nextTuesday(today), nextThursday(today)]
                  .map((date) => dayFormatter.format(date))
                  .join(' & ')}`}
                {` | ${prettyCohort.weekDayStartTime}`}
                {` - ${prettyCohort.weekDayEndTime}`}
                {` (${prettyCohort.timezone})`}
              </Flex>
            </>
          )}
        </Flex>

        <Button
          size="s"
          target={[
            {
              route: {
                path: `${buttonBasePath}/c/${salesforceId}`,
              },
            },
          ]}
          text={buttonText}
          tracking={{
            f: `application:::valueProp:::${salesforceId}:::${experimentId}~${variantId}`,
          }}
          variant="secondary"
        />
      </Flex>
    </Box>
  );
};
