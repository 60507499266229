import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Flex,
  FormFeedback,
  HexagonBadge,
  Icon,
  ListItem,
  SelectCard,
  Text,
  UnorderedList,
  UseRadioProps,
  VStack,
  chakra,
  useRadio,
  useRadioGroup,
} from '@ironhack/design-system2/components';
import {
  ArrowLeft,
  ArrowRight,
  Home,
  Plus,
  Wifi,
} from '@ironhack/design-system2/icons';
import { omit, pick } from 'ramda';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  CheckboxField,
  ContactDetailsFields,
  ListWithIcons,
  PhoneField,
  TrackBadge,
} from '@/components';
import { useFormRequest, useGtmLocalStorage, usePageContext } from '@/hooks';
import { contactFormTags, sendEvent } from '@/lib/gtm';
import {
  commonKeys,
  courseToBackground,
  getPrettyCohort,
  regionToCountry,
} from '@/lib/utils';
import type { DatoListWithIconsBlock } from '@/lib/datocms';
import type { ContactFormFull as ContactFormFullType } from '@/modules';
import type { CourseFormat, CourseTrack, Language, WebCohort } from '@/types';

import type { FlexProps } from '@ironhack/design-system2/components';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  emailOptIn: boolean;
  phone: { phoneNumber: string; country: string };
  selection: {
    format: CourseFormat | '';
    track: CourseTrack | '';
    campus: string;
  };
};

type Props = {
  contactForm: ContactFormFullType;
  onSuccess: () => void;
  onStep1Completion: (name: string) => void;
  onStep5Completion: (
    trackCode: CourseTrack,
    track: string,
    format: CourseFormat,
    campus: string
  ) => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
} & FlexProps;

const getSchema = (messages: ContactFormFullType) =>
  z
    .object({
      email: z
        .string({ required_error: messages.emailRequiredMessage })
        .email(messages.emailInvalidMessage),
      firstName: z
        .string({ required_error: messages.firstNameRequiredMessage })
        .trim()
        .min(2, messages.firstNameRequiredMessage),
      lastName: z
        .string({ required_error: messages.lastNameRequiredMessage })
        .trim()
        .min(2, messages.lastNameRequiredMessage),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phoneRequiredMessage })
              .min(4, messages.phoneInvalidMessage),
            country: z.string({
              required_error: messages.phoneRequiredMessage,
            }),
          },
          {
            invalid_type_error: messages.phoneInvalidMessage,
            required_error: messages.phoneRequiredMessage,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phoneInvalidMessage),
      selection: z.object({
        campus: z.string().nonempty(messages.campusRequiredMessage),
        format: z.string().nonempty(messages.step4RequiredMessage),
        track: z.string().nonempty(messages.courseRequiredMessage),
      }),
    })
    .required();

type CourseTrackRadioCardProps = UseRadioProps & { courseName: string };

const CourseTrackRadioCardField = (props: CourseTrackRadioCardProps) => {
  const { courseName, ...useRadioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(useRadioProps);

  const badgeColor = courseToBackground[useRadioProps.value as CourseTrack];

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      position="relative"
      width="100%"
    >
      <input {...getInputProps()} />
      <SelectCard {...getRadioProps()} isChecked={state.isChecked} w="100%">
        <Flex alignItems="center" gap={1.5} w="100%">
          <HexagonBadge
            color={`url(#${badgeColor})`}
            fill={`url(#${badgeColor})`}
            size="24px"
          />
          <Text textStyle="lBold" {...getLabelProps()}>
            {courseName}
          </Text>
        </Flex>
      </SelectCard>
    </chakra.label>
  );
};

type CourseFormatRadioCardProps = UseRadioProps & {
  courseTrackName: string;
  cardData: [
    {
      title: string;
      text: string;
      iconList: [DatoListWithIconsBlock];
    }
  ];
};

const CourseFormatRadioCardField = (props: CourseFormatRadioCardProps) => {
  const { courseTrackName, cardData, ...useRadioProps } = props;
  const {
    title,
    text,
    iconList: [iconList],
  } = cardData[0];
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(useRadioProps);

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      position="relative"
      width="100%"
    >
      <input {...getInputProps()} />
      <SelectCard
        {...getRadioProps()}
        isChecked={state.isChecked}
        isCourseFormat
        w="100%"
      >
        <VStack gap={1.5}>
          <Flex alignItems="center" gap={2} w="100%">
            <TrackBadge
              format={useRadioProps.value}
              size={5}
              track={courseTrackName as CourseTrack}
            />
            <Text textStyle="lBold" {...getLabelProps()}>
              {title}
            </Text>
          </Flex>
          <Flex color="text.secondary" direction="column" gap={3}>
            <Text textStyle="m">{text}</Text>
            <VStack alignItems="start">
              <ListWithIcons
                iconProps={{ alignSelf: 'start', color: 'link.hover', mr: 1 }}
                list={iconList}
                spacing={1.5}
              />
            </VStack>
          </Flex>
        </VStack>
      </SelectCard>
    </chakra.label>
  );
};

type CourseCampusRadioCardProps = UseRadioProps & {
  courseCampusName: string;
};

const CourseCampusRadioCardField = (props: CourseCampusRadioCardProps) => {
  const { courseCampusName, ...useRadioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(useRadioProps);

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      position="relative"
      width="100%"
    >
      <input {...getInputProps()} />
      <SelectCard {...getRadioProps()} isChecked={state.isChecked} w="100%">
        <Flex alignItems="center" gap={1.5}>
          <Icon
            as={courseCampusName === 'Remote' ? Wifi : Home}
            boxSize="24px"
          />
          <Text textStyle="lBold" {...getLabelProps()}>
            {courseCampusName === 'Remote' ? 'Online' : courseCampusName}
          </Text>
        </Flex>
      </SelectCard>
    </chakra.label>
  );
};

type CourseSelectionRadioCardProps = UseRadioProps & {
  cohort: WebCohort;
  language: Language;
};

const CourseSelectionRadioCardField = (
  props: CourseSelectionRadioCardProps
) => {
  const { cohort, language, ...useRadioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(useRadioProps);

  const prettyCohort = getPrettyCohort(cohort, language);

  return (
    <chakra.label
      {...htmlProps}
      cursor="pointer"
      display="block"
      position="relative"
      width={['100%', null, 'auto']}
    >
      <input {...getInputProps()} />
      <SelectCard {...getRadioProps()} isChecked={state.isChecked} w="full">
        <Text textStyle="lBold" {...getLabelProps()}>
          {`${prettyCohort.startDate} - ${prettyCohort.endDate}`}
        </Text>
        <Text color="text.secondary" mb={0.5} mt={2} textStyle="mBold">
          {`${prettyCohort.weekDays.join(
            ` ${cohort.format === 'ft' ? '-' : '&'} `
          )}:
           ${prettyCohort.weekDayStartTime} - ${prettyCohort.weekDayEndTime} (${
            prettyCohort.timezone
          })`}
        </Text>
        {cohort.format === 'pt' && (
          <Text color="text.secondary" mb={0.5} textStyle="mBold">
            {`${prettyCohort.weekendDays.join(' & ')}:
           ${prettyCohort.weekendStartTime} - ${prettyCohort.weekendEndTime} (${
              prettyCohort.timezone
            })`}
          </Text>
        )}
        <Text color="text.secondary" textStyle="mBold">
          {`${prettyCohort.modality} - ${prettyCohort.language}`}
        </Text>

        <Flex gap={1} mt={2}>
          <Text textStyle="mBold">{`${prettyCohort.discountPrice}`}</Text>
          {prettyCohort.price !== prettyCohort.discountPrice && (
            <Text as="s" color="text.tertiary" textStyle="mBold">
              {`${prettyCohort.price}`}
            </Text>
          )}
        </Flex>
      </SelectCard>
    </chakra.label>
  );
};

export const ContactFormFull = (props: Props): ReactElement => {
  const {
    contactForm,
    onSuccess,
    onStep1Completion,
    onStep5Completion,
    step,
    setStep,
    ...flexProps
  } = props;
  const {
    backButtonText,
    campuses,
    courses,
    errorMessage,
    newsletterLabel,
    nextButtonText,
    phoneLabel,
    phonePlaceholder,
    step1Cta,
    step4FullTimeCard,
    step4PartTimeCard,
    step5ErrorMessage,
    step6MoreDates,
    step6RequiredMessage,
  } = contactForm;

  const {
    params: { language, region },
    pageData: { gaCategory },
  } = usePageContext();
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);

  const formSchema = getSchema(contactForm);

  const formMethods = useForm<FormData>({
    defaultValues: {
      phone: { country: regionToCountry(region) },
      selection: {
        campus: '',
        track: '',
        format: '',
      },
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  } | null>();
  const [selectedCourseTrack, setSelectedCourseTrack] = useState<string>('');
  const [selectedCourseFormat, setSelectedCourseFormat] = useState<string>('');
  const [selectedCourseCampus, setSelectedCourseCampus] = useState<string>('');
  const [isLoadingCohorts, setIsLoadingCohorts] = useState<boolean>(false);
  const [availableCohorts, setAvailableCohorts] = useState<WebCohort[]>([]);
  const [cohortLimit, setCohortLimit] = useState<number>(3);
  const [selectedCourse, setSelectedCourse] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (step === 2) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [step]);

  const handleCourseTrackSelected = (courseTrack: CourseTrack) => {
    setSelectedCourseTrack(courseTrack);
    setValue('selection.track', courseTrack);
  };

  const handleCourseFormatSelected = (courseFormat: CourseFormat) => {
    setSelectedCourseFormat(courseFormat);
    setValue('selection.format', courseFormat);
  };

  const handleCourseCampusSelected = (courseCampus: string) => {
    setSelectedCourseCampus(courseCampus);
    setValue('selection.campus', courseCampus);
  };

  const handleCourseSelected = (cohortSalesforceId: string) => {
    setSelectedCourse(cohortSalesforceId);
  };

  const {
    getRadioProps: getRadioPropsCourseTrack,
    getRootProps: getRootPropsCourseTrack,
    isDisabled: isDisabledCourseTrack,
  } = useRadioGroup({
    name: 'selection.track',
    onChange: handleCourseTrackSelected,
    value: selectedCourseTrack,
  });
  const {
    getRadioProps: getRadioPropsCourseFormat,
    getRootProps: getRootPropsCourseFormat,
    isDisabled: isDisabledCourseFormat,
  } = useRadioGroup({
    name: 'selection.format',
    onChange: handleCourseFormatSelected,
    value: selectedCourseFormat,
  });
  const {
    getRadioProps: getRadioPropsCourseCampus,
    getRootProps: getRootPropsCourseCampus,
    isDisabled: isDisabledCourseCampus,
  } = useRadioGroup({
    name: 'selection.campus',
    onChange: handleCourseCampusSelected,
    value: selectedCourseCampus,
  });
  const {
    getRadioProps: getRadioPropsCourseSelection,
    getRootProps: getRootPropsCourseSelection,
    isDisabled: isDisabledCourseSelection,
  } = useRadioGroup({
    onChange: handleCourseSelected,
    value: selectedCourse,
  });
  const formRequest = useFormRequest();
  const [previousPageType] = useSessionStorage<string>('pageTypePrevious');
  const [, setGtmLocalStorage] = useGtmLocalStorage();

  const step5Complete = async () => {
    const { selection } = getValues();
    const { track, format, campus } = selection;
    const validationResult = await trigger('selection.campus');
    if (!validationResult) {
      setFormFeedback({
        status: 'error',
        message: errors.selection?.campus?.message as string,
      });
      return null;
    }

    try {
      setIsLoadingCohorts(true);
      const cohortData = await fetch('/api/cohorts', {
        body: JSON.stringify({
          campus,
          format,
          language,
          region,
          track,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      const cohorts = (await cohortData.json()) as { result: WebCohort[] };
      const cohortsResult = cohorts.result;
      if (cohortsResult.length === 0) {
        setFormFeedback({
          status: 'error',
          message: step5ErrorMessage,
        });
        return null;
      }

      setAvailableCohorts(cohortsResult);
      onStep5Completion(
        track as CourseTrack,
        commonKeys[language][track],
        commonKeys[language][format] as CourseFormat,
        commonKeys[language][campus]
      );
      return validationResult;
    } catch (error) {
      return error;
    } finally {
      setIsLoadingCohorts(false);
    }
  };

  const step6Complete = async () => {
    if (!selectedCourse) {
      setFormFeedback({ status: 'error', message: step6RequiredMessage });
      return null;
    }

    setIsSubmitting(true);
    try {
      await formRequest({
        data: {
          ...omit(['selection'], getValues()),
          courseId: selectedCourse,
          language,
        },
        formType: 'contact',
      });

      const formReferrer = sessionStorage.getItem('formReferrer') || 'default';
      const defaultEventCategory =
        previousPageType && previousPageType !== 'undefined'
          ? previousPageType
          : gaCategory;
      const { eventAction, eventCategory = defaultEventCategory } =
        contactFormTags[formReferrer];

      sendEvent({
        campus: selectedCourseCampus,
        course: selectedCourseTrack,
        email: getValues().email,
        eventAction,
        eventCategory,
        eventLabel: `${selectedCourseCampus}::${selectedCourseTrack}`,
        pageType: eventCategory,
      });

      const {
        phone: { phoneNumber, country },
      } = getValues();
      setGtmLocalStorage({
        applicationData: {
          campus: selectedCourseCampus,
          course: selectedCourseTrack,
          phoneNumber,
          ...pick(['email', 'firstName', 'lastName'], getValues()),
        },
        countryName: country,
      });

      onSuccess();
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onStepComplete = async (stepNumber: number) => {
    let validationResult = false;

    switch (stepNumber) {
      case 1:
        const { firstName } = getValues();
        validationResult = await trigger(['firstName', 'lastName', 'email']);
        if (validationResult) onStep1Completion(firstName);

        break;
      case 2:
        validationResult = await trigger('phone');
        break;
      case 3:
        validationResult = await trigger('selection.track');
        if (!validationResult) {
          setFormFeedback({
            status: 'error',
            message: errors.selection?.track?.message as string,
          });
        }
        break;
      case 4:
        validationResult = await trigger('selection.format');
        if (!validationResult)
          setFormFeedback({
            status: 'error',
            message: errors.selection?.format?.message as string,
          });
        break;
      case 5:
        validationResult = (await step5Complete()) as boolean;
        break;
      case 6:
        await step6Complete();
        break;
      default:
        return null;
    }

    if (validationResult) {
      setStep((currentStep) => currentStep + 1);
      setFormFeedback(null);
    }
  };

  return (
    <Flex direction="column" {...flexProps} mt={2}>
      <FormProvider {...formMethods}>
        <form noValidate>
          <Flex alignItems="center" direction="column">
            {step === 1 && (
              <>
                <ContactDetailsFields
                  fields={pick(
                    [
                      'chooseBetween',
                      'completeFields',
                      'emailLabel',
                      'emailPlaceholder',
                      'firstNameLabel',
                      'firstNamePlaceholder',
                      'lastNameLabel',
                      'lastNamePlaceholder',
                      'phoneLabel',
                      'phonePlaceholder',
                    ],
                    contactForm
                  )}
                  onGoogleClick={() => {
                    setFormFeedback(null);
                    setIsGoogleSubmitting(true);
                  }}
                  onGoogleError={() => {
                    setIsGoogleSubmitting(false);
                    setFormFeedback({
                      status: 'error',
                      message: errorMessage,
                    });
                  }}
                  onGoogleSuccess={async (googleData) => {
                    setIsGoogleSubmitting(false);
                    setValue('email', googleData.email);
                    setValue('firstName', googleData.firstName);
                    setValue('lastName', googleData.lastName);
                    const isValid = await trigger([
                      'email',
                      'firstName',
                      'lastName',
                    ]);
                    if (!isValid) return;

                    onStep1Completion(googleData.firstName);
                    clearErrors();
                    setStep((currentStep) => currentStep + 1);
                  }}
                  setFormValues={false}
                />
                <CheckboxField
                  isRequired
                  label={newsletterLabel}
                  mb={2}
                  mt={[2, null, 3]}
                  name="emailOptIn"
                />
                <Button
                  isLoading={isGoogleSubmitting}
                  onClick={() => onStepComplete(step)}
                >
                  {step1Cta}
                </Button>
              </>
            )}
            {step === 2 && (
              <PhoneField
                error={
                  errors.phone?.message || errors.phone?.phoneNumber?.message
                }
                isRequired
                label={phoneLabel}
                name="phone"
                placeholder={phonePlaceholder}
              />
            )}
            {step === 3 && (
              <Flex
                {...getRootPropsCourseTrack()}
                direction="column"
                gap={2}
                w="100%"
              >
                {courses.map((course: { name: string; code: string }) => (
                  <CourseTrackRadioCardField
                    courseName={course.name}
                    isDisabled={isDisabledCourseTrack}
                    key={course.name}
                    {...getRadioPropsCourseTrack({
                      value: course.code,
                    })}
                  />
                ))}
              </Flex>
            )}
            {step === 4 && (
              <Flex
                {...getRootPropsCourseFormat()}
                direction={['column', null, 'row']}
                gap={[4, null, 2]}
                w="100%"
              >
                <CourseFormatRadioCardField
                  cardData={step4PartTimeCard}
                  courseTrackName={selectedCourseTrack}
                  isDisabled={isDisabledCourseFormat}
                  {...getRadioPropsCourseFormat({
                    value: 'pt',
                  })}
                />
                <CourseFormatRadioCardField
                  cardData={step4FullTimeCard}
                  courseTrackName={selectedCourseTrack}
                  isDisabled={isDisabledCourseFormat}
                  {...getRadioPropsCourseFormat({
                    value: 'ft',
                  })}
                />
              </Flex>
            )}
            {step === 5 && (
              <Flex
                {...getRootPropsCourseCampus()}
                direction="column"
                gap={2}
                w="100%"
              >
                {campuses.map((campus: { name: string; code: string }) => (
                  <CourseCampusRadioCardField
                    courseCampusName={campus.name}
                    isDisabled={isDisabledCourseCampus}
                    key={campus.code}
                    {...getRadioPropsCourseCampus({
                      value: campus.code,
                    })}
                  />
                ))}
              </Flex>
            )}
            {step === 6 && (
              <>
                <UnorderedList
                  {...getRootPropsCourseSelection()}
                  mx={0}
                  spacing={[1, null, 2]}
                  styleType="none"
                  w="full"
                >
                  {availableCohorts.slice(0, cohortLimit).map((cohort) => (
                    <ListItem key={cohort.id} w="full">
                      <CourseSelectionRadioCardField
                        cohort={cohort}
                        isDisabled={isDisabledCourseSelection}
                        language={language}
                        {...getRadioPropsCourseSelection({
                          value: cohort.external_ids.salesforce,
                        })}
                      />
                    </ListItem>
                  ))}
                </UnorderedList>
                {availableCohorts.length > 3 &&
                  cohortLimit < availableCohorts.length && (
                    <Button
                      // @ts-expect-error -- Icons tend to have errors
                      leftIcon={Plus}
                      mt={2}
                      onClick={() =>
                        setCohortLimit(
                          (currentCohortLimit) => currentCohortLimit + 3
                        )
                      }
                      variant="tertiary"
                    >
                      {step6MoreDates}
                    </Button>
                  )}
              </>
            )}
            {step > 1 && (
              <>
                {formFeedback?.status && (
                  <FormFeedback mt={2} {...formFeedback} />
                )}
                <Flex gap={1} mt={[2, null, 3]} w="full">
                  <Button
                    // @ts-expect-error -- Icons tend to have errors
                    leftIcon={ArrowLeft}
                    onClick={() => {
                      setFormFeedback(null);
                      if (step > 1) setStep((currentStep) => currentStep - 1);
                    }}
                    variant="secondary"
                    w="full"
                  >
                    {backButtonText}
                  </Button>
                  <Button
                    isLoading={isLoadingCohorts || isSubmitting}
                    onClick={() => onStepComplete(step)}
                    // @ts-expect-error -- Icons tend to have errors
                    rightIcon={ArrowRight}
                    variant="primary"
                    w="full"
                  >
                    {nextButtonText}
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
