import {
  Box,
  Container,
  Flex,
  forwardRef,
} from '@ironhack/design-system2/components';
import { backgroundToTokens } from '@ironhack/design-system2';

import { DatoModuleFormat } from '@/lib/datocms';

import type { BoxProps, FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement, ReactNode } from 'react';

type ModuleWrapperProps = {
  outerProps?: BoxProps;
  innerProps?: FlexProps;
  children?: ReactNode;
  moduleFormat: DatoModuleFormat;
};

export const WRAPPER_PADDING = [2, null, 7, 10];

export const ModuleWrapper = forwardRef(
  (props: ModuleWrapperProps, ref): ReactElement => {
    const {
      innerProps,
      outerProps,
      children,
      moduleFormat: { background, spacingBottom, spacingTop },
    } = props;

    const { bg, color } = backgroundToTokens(background);
    const bottomArray = Array.isArray(spacingBottom)
      ? spacingBottom
      : [spacingBottom];
    const topArray = Array.isArray(spacingTop) ? spacingTop : [spacingTop];

    return (
      <Box
        bg={bg}
        color={color}
        pb={bottomArray.map((pad) => pad && Number.parseInt(pad) / 8)}
        pt={topArray.map((pad) => pad && Number.parseInt(pad) / 8)}
        ref={ref}
        w="full"
        {...outerProps}
      >
        <Container display="flex" h="full" maxW="container.xl" p={0}>
          <Flex px={WRAPPER_PADDING} w="full" {...innerProps}>
            {children}
          </Flex>
        </Container>
      </Box>
    );
  }
);
