import {
  Flex,
  Icon,
  Tag,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import {
  ArrowRight,
  ChevronDown,
  ChevronRight,
} from '@ironhack/design-system2/icons';
import { Image } from 'react-datocms';

import { Link } from '@/components';
import type { DatoImage, DatoTargetBlock } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type MenuItem = {
  text: string;
  tag?: string;
  eventLabel?: string;
  image?: DatoImage;
  children?: MenuItem[];
  target?: [DatoTargetBlock];
};

type Props = {
  isSelected?: boolean;
  isCompact?: boolean;
  level: 1 | 2 | 3;
} & MenuItem &
  Omit<FlexProps, 'children'>;

const iconMap = {
  ChevronRight,
  ChevronDown,
  ArrowRight,
} as const;

const getLevelProps = (
  level: number,
  hasTarget: boolean,
  isMobile: boolean
): FlexProps => {
  if (level === 1)
    return isMobile
      ? { justifyContent: 'space-between', w: 'full' }
      : { gap: 0.5 };
  if (level === 2)
    return isMobile
      ? { justifyContent: 'space-between', w: 'full' }
      : { gap: 2, justifyContent: 'space-between' };
  if (level === 3)
    return isMobile
      ? { w: 'full', justifyContent: 'space-between' }
      : { gap: 2 };
};

const getIconName = (
  level: number,
  hasTarget: boolean,
  isMobile: boolean
): keyof typeof iconMap => {
  if (hasTarget) return null;
  if (level === 1) return isMobile ? 'ChevronRight' : 'ChevronDown';
  if (level === 2) return 'ChevronRight';
  if (level === 3 && isMobile) return 'ArrowRight';
};

export const MenuItem = (props: Props): ReactElement => {
  const {
    image,
    isCompact,
    isSelected,
    level,
    tag,
    target,
    text,
    ...flexProps
  } = props;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const hasTarget = target?.length > 0;

  const levelProps = getLevelProps(level, hasTarget, isMobile);

  const iconName = getIconName(level, hasTarget, isMobile);
  const IconComponent = iconMap[iconName];
  const hideIcon = !iconName || (level === 3 && !isMobile);

  return (
    <Flex
      alignItems="center"
      as={hasTarget ? Link : 'div'}
      cursor="pointer"
      {...levelProps}
      {...(hasTarget && { ...target[0], withoutDecoration: true })}
      {...(!hasTarget && { role: 'button', tabIndex: 0 })}
      {...(level === 3 && { direction: isCompact ? 'row' : 'column' })}
      {...flexProps}
    >
      <Flex
        alignItems="center"
        gap={[2, null, 3]}
        {...(level === 3 && { direction: isCompact ? 'row' : 'column' })}
      >
        {image && level === 3 && (
          <Image
            data={image.responsiveImage}
            fadeInDuration={0}
            style={{
              height: !isCompact ? '100%' : isMobile ? '40px' : '60px',
              width: !isCompact ? '100%' : isMobile ? '40px' : '60px',
            }}
          />
        )}
        <Text
          // Needed to avoid layout shift when item is selected and gets bold and hence bigger
          _before={{
            content: `"${text}"`,
            display: 'block',
            height: 0,
            overflow: 'hidden',
            textStyle: 'mBold',
            visibility: 'hidden',
          }}
          noOfLines={1}
          textStyle={isMobile ? 'lBold' : isSelected ? 'mBold' : 'm'}
        >
          {text}
        </Text>
        {tag && level === 2 && <Tag colorScheme="pink" size="s" text={tag} />}
      </Flex>
      {!hideIcon && <Icon as={IconComponent} boxSize="24px" />}
    </Flex>
  );
};
