import { ReactElement } from 'react';
import { FormField, Select } from '@ironhack/design-system2/components';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormControlProps } from '@ironhack/design-system2/components';

type Props = {
  label?: string;
  error?: string;
  name: string;
  isRequired: boolean;
  placeholder: string;
  options: Array<{ key: string; text: string }>;
} & FormControlProps;

export const SelectField = (props: Props): ReactElement => {
  const {
    error,
    isRequired,
    label,
    name,
    options,
    placeholder,
    ...formFieldProps
  } = props;
  const { control } = useFormContext();
  return (
    <FormField
      error={error}
      isInvalid={Boolean(error)}
      isRequired={isRequired}
      label={label}
      name={name}
      {...formFieldProps}
    >
      <Controller
        control={control}
        defaultValue={false}
        name={name}
        render={({ field: { onBlur, onChange, value, ref } }) => (
          <Select
            color="text.primary"
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            ref={ref}
            value={value as string}
          >
            {options.map(({ text, key }) => (
              <option key={key} value={key}>
                {text}
              </option>
            ))}
          </Select>
        )}
        rules={{ required: isRequired }}
      />
    </FormField>
  );
};
