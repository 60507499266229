import { Button, Flex } from '@ironhack/design-system2/components';
import { StructuredTextGraphQlResponse } from 'react-datocms';

import { CheckboxField, StructuredContent } from '@/components';

import type {
  ButtonProps,
  FlexProps,
} from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type Props = {
  buttonProps?: ButtonProps;
  buttonText: string;
  isLoading: boolean;
  legalText: StructuredTextGraphQlResponse;
  newsletterLabel?: string;
  withNewsletter?: boolean;
} & FlexProps;

export const FormSubmit = (props: Props): ReactElement => {
  const {
    buttonProps = {},
    buttonText,
    isLoading,
    legalText,
    newsletterLabel,
    withNewsletter = false,
    ...flexProps
  } = props;

  return (
    <Flex
      alignItems="center"
      direction="column"
      gap={2}
      w="full"
      {...flexProps}
    >
      {withNewsletter && (
        <CheckboxField isRequired label={newsletterLabel} name="newsletter" />
      )}
      <Button isLoading={isLoading} type="submit" {...buttonProps}>
        {buttonText}
      </Button>
      <StructuredContent
        color="text.secondary"
        data={legalText}
        textAlign="center"
        textStyle="m"
      />
    </Flex>
  );
};
