import { GridCard as DsGridCard } from '@ironhack/design-system2/components';
import { Image } from 'react-datocms';

import { Link } from '@/components';
import type { DatoGridCardBlock } from '@/lib/datocms';

import type { CardProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type Props = {
  card: DatoGridCardBlock;
  orientation?: 'horizontal' | 'vertical';
  size?: 's';
} & Omit<CardProps, 'size'>;

export const GridCard = (props: Props): ReactElement => {
  const {
    card: {
      image,
      tag: [tag],
      target: [target],
      ...cardData
    },
    ...cardProps
  } = props;

  return (
    <Link withoutDecoration {...target}>
      <DsGridCard
        tag={tag}
        {...(image && { image: <Image data={image.responsiveImage} /> })}
        {...cardData}
        {...cardProps}
      />
    </Link>
  );
};
