import { zodResolver } from '@hookform/resolvers/zod';
import { Flex, FormFeedback } from '@ironhack/design-system2/components';
import { omit, pick } from 'ramda';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  ContactDetailsFields,
  FormSubmit,
  PhoneField,
  SelectField,
} from '@/components';
import {
  useExperimentApplication,
  useFormRequest,
  useGtmLocalStorage,
  usePageContext,
} from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import { regionToCampusMap, regionToCountry } from '@/lib/utils';
import type { ApplyForm as ApplyFormType } from '@/modules';
import type { WebCohort } from '@/types';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  newsletter: boolean;
  phone: { phoneNumber: string; country: string };
  referral: string;
};

type Props = {
  applyForm: ApplyFormType;
  cohort: WebCohort;
  onStep2: () => void;
  onSuccess: () => void;
} & FlexProps;

const getSchema = (messages: ApplyFormType) =>
  z
    .object({
      email: z
        .string({ required_error: messages.emailRequiredMessage })
        .email(messages.emailInvalidMessage),
      firstName: z
        .string({ required_error: messages.firstNameRequiredMessage })
        .trim()
        .min(2, messages.firstNameRequiredMessage),
      lastName: z
        .string({ required_error: messages.lastNameRequiredMessage })
        .trim()
        .min(2, messages.lastNameRequiredMessage),
      newsletter: z.boolean(),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phoneRequiredMessage })
              .min(4, messages.phoneInvalidMessage),
            country: z.string({
              required_error: messages.phoneRequiredMessage,
            }),
          },
          {
            invalid_type_error: messages.phoneInvalidMessage,
            required_error: messages.phoneRequiredMessage,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phoneInvalidMessage),
      referral: z.string(),
    })
    .required();

export const ApplyForm = (props: Props): ReactElement => {
  const { applyForm, cohort, onSuccess, onStep2, ...flexProps } = props;
  const {
    buttonText,
    errorMessage,
    legalText,
    newsletterLabel,
    phoneLabel,
    phonePlaceholder,
    referralLabel,
    referralOptions,
    referralPlaceholder,
  } = applyForm;
  const {
    params: { language, region },
  } = usePageContext();
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [showReferral, setShowReferral] = useState(true);

  const formSchema = getSchema(applyForm);

  const formMethods = useForm<FormData>({
    defaultValues: {
      phone: { country: regionToCountry(region) },
      newsletter: false,
      referral: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    trigger,
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  } | null>();
  const formRequest = useFormRequest();
  const [campus] = useSessionStorage('campus', regionToCampusMap[region][0]);
  const [, setGtmLocalStorage] = useGtmLocalStorage();
  const { experimentId, salesforceId, variantId } = useExperimentApplication(
    'valueProp',
    cohort.external_ids.salesforce
  );

  const onSubmit = async (formData: FormData): Promise<void> => {
    setFormFeedback(null);
    try {
      await formRequest({
        data: {
          ...omit(['newsletter'], formData),
          courseId: salesforceId,
          emailOptIn: formData.newsletter,
          language,
          websiteCampusPage: campus,
        },
        experimentId,
        formType: 'apply',
        variantId,
      });

      sendEvent({
        campus: cohort.campus,
        course: cohort.track,
        email: formData.email,
        eventAction: 'send cohort application',
        eventCategory: 'PDP',
        eventLabel: `${cohort.campus}::${cohort.track}::${cohort.format}`,
        format: cohort.format,
        pageType: 'PDP',
      });
      const {
        phone: { phoneNumber, country },
      } = formData;
      setGtmLocalStorage({
        applicationData: {
          campus: cohort.campus,
          course: cohort.track,
          format: cohort.format,
          phoneNumber,
          ...pick(['email', 'firstName', 'lastName'], formData),
        },
        countryName: country,
      });
      onSuccess();
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    }
  };

  return (
    <Flex direction="column" {...flexProps}>
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" direction="column">
            {step === 1 && (
              <ContactDetailsFields
                fields={pick(
                  [
                    'chooseBetween',
                    'completeFields',
                    'emailLabel',
                    'emailPlaceholder',
                    'firstNameLabel',
                    'firstNamePlaceholder',
                    'lastNameLabel',
                    'lastNamePlaceholder',
                    'phoneLabel',
                    'phonePlaceholder',
                  ],
                  applyForm
                )}
                onGoogleClick={() => {
                  setFormFeedback(null);
                  setIsGoogleSubmitting(true);
                }}
                onGoogleError={() => {
                  setIsGoogleSubmitting(false);
                  setFormFeedback({
                    status: 'error',
                    message: errorMessage,
                  });
                }}
                onGoogleSuccess={async (googleData) => {
                  setIsGoogleSubmitting(false);
                  setValue('email', googleData.email);
                  setValue('firstName', googleData.firstName);
                  setValue('lastName', googleData.lastName);
                  const isValid = await trigger([
                    'email',
                    'firstName',
                    'lastName',
                  ]);
                  if (!isValid) return;

                  clearErrors();
                  if (getValues('referral')) {
                    setShowReferral(false);
                  }
                  setStep(2);
                  onStep2();
                }}
                setFormValues={false}
                withPhone
              />
            )}
            {step === 2 && (
              <PhoneField
                error={
                  errors.phone?.message || errors.phone?.phoneNumber?.message
                }
                isRequired
                label={phoneLabel}
                name="phone"
                placeholder={phonePlaceholder}
              />
            )}
            {showReferral && (
              <SelectField
                isRequired={false}
                label={referralLabel}
                mt={2}
                name="referral"
                options={referralOptions}
                placeholder={referralPlaceholder}
              />
            )}
            <FormSubmit
              buttonText={buttonText}
              isLoading={isSubmitting || isGoogleSubmitting}
              legalText={legalText}
              mt={[2, null, 3]}
              newsletterLabel={newsletterLabel}
              withNewsletter
            />
            {formFeedback?.status && <FormFeedback mt={2} {...formFeedback} />}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
