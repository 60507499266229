import {
  Flex,
  Radio,
  RadioGroup,
  Select,
  Text,
} from '@ironhack/design-system2/components';
import { ChangeEvent, ReactElement } from 'react';

import type {
  RadioProps,
  SelectProps,
} from '@ironhack/design-system2/components';

type Props = (RadioProps | SelectProps) & {
  color?: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  value: string;
  values: string[];
};

export const EXPAND_COLLAPSE_RADIO_OPTIONS_LIMIT = 3;

export const ExpandCollapseFilter = (props: Props): ReactElement => {
  const { color, onChangeValue, placeholder, values, ...controlProps } = props;

  if (values.length <= EXPAND_COLLAPSE_RADIO_OPTIONS_LIMIT) {
    return (
      <RadioGroup
        // @ts-expect-error -- says onChangeValue is incompatible but it works
        onChange={onChangeValue}
        w={['100%', null, 'auto']}
        {...controlProps}
      >
        <Flex gap={5} justify="space-around">
          {values.map((filter) => (
            <Radio key={filter} value={filter} variant="outline">
              {color ? <Text color={color}>{filter}</Text> : filter}
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
    );
  }

  return (
    <Select
      color="text.secondary"
      maxW={[null, null, '20rem']}
      // @ts-expect-error -- says function is incompatible but it works
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        onChangeValue(e.target.value)
      }
      placeholder={placeholder}
      variant="outline"
      {...controlProps}
    >
      {values.map((name) => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </Select>
  );
};
