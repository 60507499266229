import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  chakra,
} from '@ironhack/design-system2/components';
import { useEffect, useRef, useState } from 'react';
import { X } from '@ironhack/design-system2/icons';
import { Image } from 'react-datocms';
import { useMeasure } from 'react-use';

import type { DatoStory } from '@/lib/datocms';

import type { ReactElement, TouchEvent } from 'react';

type Props = {
  onExit: () => void;
  onNext: () => void | null;
  onPrevious: () => void | null;
  story: DatoStory;
};

const SWIPE_THRESHOLD = 40; // Less than 40 pixels is not a swipe (number made up by me testing around)
const TAP_THRESHOLD = 200; // Less than 200 ms is considered a tap (number made up by me testing around)

export const StoryPlayerMobile = (props: Props): ReactElement => {
  const { onExit, onNext, onPrevious, story } = props;
  const { userName, userPicture, userRole, company, video } = story;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState(0);
  const [storyRef, { width: storyWidth }] = useMeasure();
  const swipeRef = useRef({ touchStart: { x: 0, time: Date.now() } });

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    // This enables the tap-to-pause feature
    void videoRef.current.pause();

    swipeRef.current.touchStart.x = event.targetTouches[0].clientX;
    swipeRef.current.touchStart.time = Date.now();
  };

  const handleTouchEnd = (event: TouchEvent<HTMLDivElement>) => {
    // This enables the tap-to-pause feature
    void videoRef.current.play().catch(() => {
      // Prevent error when pause interrupts play https://goo.gl/LdLk22
    });

    const touchEndX = event.changedTouches[0].clientX;
    const touchStartX = swipeRef.current.touchStart.x;
    const xDistance = touchStartX - touchEndX;
    const elapsedTime = Date.now() - swipeRef.current.touchStart.time;
    const isSwipe = Math.abs(xDistance) > SWIPE_THRESHOLD;
    const isTap = !isSwipe && elapsedTime < TAP_THRESHOLD;

    if (!isSwipe && !isTap) return;

    if (isTap) {
      // Detect if the left or right side of the video has been tapped
      const isTapRight = touchStartX > storyWidth / 2;
      if (isTapRight && onNext) {
        return onNext();
      } else if (!isTapRight && onPrevious) {
        return onPrevious();
      }
    }

    if (isSwipe) {
      if (xDistance > 0 && onNext) {
        return onNext();
      } else if (xDistance < 0 && onPrevious) {
        return onPrevious();
      }
    }
  };

  useEffect(() => {
    const handleProgress = () => {
      setProgress((videoRef.current.currentTime / video.video.duration) * 100);
    };

    const videoElement = videoRef.current;

    videoElement.addEventListener('timeupdate', handleProgress);

    return () => {
      videoElement.removeEventListener('timeupdate', handleProgress);
    };
  }, [video.video.duration]);

  return (
    <Box
      _before={{
        backgroundImage:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%);',
        content: '""',
        height: '100%',
        position: 'absolute',
        width: '100%',
      }}
      h="full"
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      position="relative"
      ref={storyRef}
      w="full"
      zIndex={100}
    >
      <chakra.video
        autoPlay
        h="full"
        id="video"
        objectFit="cover"
        ref={videoRef}
        src={video.video.mp4Url}
        w="full"
        zIndex={100}
      />
      <Flex direction="column" position="absolute" px={2} top={1.5} w="full">
        <Flex alignItems="center" gap={1.5}>
          <Progress value={progress} variant="story" w="full" />
          <Icon
            as={X}
            boxSize="24px"
            color="stroke.white"
            cursor="pointer"
            onClick={(event) => {
              event.stopPropagation();
              onExit();
            }}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={1.5}>
          <Flex alignItems="center" gap={0.5}>
            <Box
              background="linear-gradient(#fff, #fff) padding-box, var(--chakra-colors-background-brand) border-box;"
              border="2px solid transparent"
              borderRadius="full"
              boxSize="48px"
              overflow="hidden"
            >
              <Image data={userPicture.responsiveImage} />
            </Box>
            <Flex color="text.inverse" direction="column">
              <Text textStyle="mBold">{userName}</Text>
              <Text textStyle="xs">{userRole}</Text>
            </Flex>
          </Flex>
          <Image data={company.horizontalBackgroundLogo.responsiveImage} />
        </Flex>
      </Flex>
    </Box>
  );
};
