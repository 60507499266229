import { Center, Flex, Icon, Text } from '@ironhack/design-system2/components';
import { useMeasure } from 'react-use';
import dynamic from 'next/dynamic';
import { Mail } from '@ironhack/design-system2/icons';
import { useEffect } from 'react';

import type { DatoButtonBlock } from '@/lib/datocms';
import { Button } from '@/components/button';

import type {
  ButtonProps,
  FlexProps,
} from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

const Confetti = dynamic(() => import('react-confetti'), { ssr: false });

type Props = {
  button: [DatoButtonBlock];
  buttonProps?: ButtonProps;
  text: string;
  title: string;
} & FlexProps;

function drawConfettiShape(ctx: CanvasRenderingContext2D) {
  const w = 5;
  const h = 15;
  ctx.beginPath();
  ctx.fillRect(-w / 2, -h / 2, w, h);
  ctx.closePath();
  ctx.restore();
}

export const FormThankYou = (props: Props): ReactElement => {
  const {
    button: [button],
    buttonProps = {},
    text,
    title,
    ...flexProps
  } = props;

  const [
    confettiBoxRef,
    {
      width: confettiWidth,
      height: confettiHeight,
      top: confettiTop,
      left: confettiLeft,
    },
  ] = useMeasure();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Flex alignItems="center" direction="column" w="full" {...flexProps}>
      <Text textStyle="2xl">{title}</Text>
      <Center
        h="104px"
        mt={1}
        position="relative"
        ref={confettiBoxRef}
        w="full"
      >
        <Confetti
          drawShape={drawConfettiShape}
          height={confettiHeight}
          numberOfPieces={40}
          style={{
            position: 'absolute',
            top: confettiTop,
            left: confettiLeft,
            zIndex: 1,
          }}
          width={confettiWidth}
        />
        <Center
          bg="fill.primary"
          borderRadius="full"
          boxSize="56px"
          zIndex={10}
        >
          <Icon as={Mail} boxSize="24px" color="stroke.white" />
        </Center>
      </Center>
      <Text mt={3} textStyle="l">
        {text}
      </Text>
      <Button {...button} mt={3} {...buttonProps} />
    </Flex>
  );
};
