import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Flex,
  FormFeedback,
} from '@ironhack/design-system2/components';
import { omit, pick } from 'ramda';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCookie, useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  CheckboxField,
  ContactDetailsFields,
  StructuredContent,
} from '@/components';
import { useFormRequest, useGtmLocalStorage, usePageContext } from '@/hooks';
import type { DatoCourse } from '@/lib/datocms';
import { sendEvent, syllabusFormTags } from '@/lib/gtm';
import {
  regionToCampusMap,
  regionToCountry,
  urlToDatoLocale,
} from '@/lib/utils';
import type { SyllabusForm as SyllabusFormType } from '@/modules';

import type { ReactElement } from 'react';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  newsletter: boolean;
  phone: { phoneNumber: string; country: string };
  referral: string;
};

type Props = {
  onSuccess: () => void;
  syllabusForm: SyllabusFormType;
};

const getSchema = (messages: SyllabusFormType) =>
  z
    .object({
      email: z.string().email(messages.emailInvalidMessage),
      firstName: z.string().trim().min(2, messages.firstNameRequiredMessage),
      lastName: z.string().trim().min(2, messages.lastNameRequiredMessage),
      newsletter: z.boolean(),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phoneRequiredMessaged })
              .min(4, messages.phoneInvalidMessage),
            country: z.string({
              required_error: messages.phoneRequiredMessaged,
            }),
          },
          {
            invalid_type_error: messages.phoneInvalidMessage,
            required_error: messages.phoneRequiredMessaged,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phoneInvalidMessage),
    })
    .required();

export const SyllabusForm = (props: Props): ReactElement => {
  const { syllabusForm, onSuccess } = props;
  const { newsletterLabel, buttonText, legalText, errorMessage } = syllabusForm;
  const {
    params: { language, region },
    cmsData: {
      page: { course },
    },
    pageData: { gaCategory },
  } = usePageContext<{ page: { course: Pick<DatoCourse, 'code'> } }>();
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);

  const formSchema = getSchema(syllabusForm);

  const formMethods = useForm<FormData>({
    defaultValues: {
      newsletter: false,
      phone: { country: regionToCountry(region) },
      referral: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  } | null>();

  const formRequest = useFormRequest();
  const [campus] = useSessionStorage('campus', regionToCampusMap[region][0]);
  const [countryName] = useCookie('ss_country');
  const [, setGtmLocalStorage] = useGtmLocalStorage();

  const onSubmit = async (formData: FormData): Promise<void> => {
    setFormFeedback(null);
    try {
      await formRequest({
        data: {
          ...omit(['newsletter'], formData),
          language,
          bootcamp: `${course.code.toUpperCase()}FT`,
          campus,
          emailOptIn: formData.newsletter,
        },
        formType: 'syllabus',
      });

      const labels = { region, campus };
      const formReferrer = sessionStorage.getItem('formReferrer') || 'default';
      const { eventCategory = gaCategory, labelKey } =
        syllabusFormTags[formReferrer];

      sendEvent({
        email: formData.email,
        eventAction: 'send syllabus request',
        eventCategory,
        eventLabel: `${labels[labelKey]}::${course.code}`,
        pageType: eventCategory,
      });
      setGtmLocalStorage({
        applicationData: {
          campus,
          course: course.code,
          ...pick(['email', 'firstName', 'lastName'], formData),
        },
        countryName:
          countryName && countryName !== 'undefined'
            ? countryName
            : urlToDatoLocale[`${region}${language}`].slice(-2),
      });
      onSuccess();
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    }
  };

  return (
    <Flex direction="column">
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" direction="column">
            <ContactDetailsFields
              fields={pick(
                [
                  'chooseBetween',
                  'completeFields',
                  'emailLabel',
                  'emailPlaceholder',
                  'firstNameLabel',
                  'firstNamePlaceholder',
                  'lastNameLabel',
                  'lastNamePlaceholder',
                  'phoneLabel',
                  'phonePlaceholder',
                ],
                syllabusForm
              )}
              mt={4}
              onGoogleClick={() => {
                setFormFeedback(null);
                setIsGoogleSubmitting(true);
              }}
              onGoogleError={() => {
                setIsGoogleSubmitting(false);
                setFormFeedback({
                  status: 'error',
                  message: errorMessage,
                });
              }}
              onGoogleSuccess={() => {
                setIsGoogleSubmitting(false);
                void handleSubmit(onSubmit)();
              }}
              withPhone
            />
            <CheckboxField
              error={errors.newsletter?.message}
              isRequired
              label={newsletterLabel}
              mt={3}
              name="newsletter"
            />
            <Button
              isLoading={isSubmitting || isGoogleSubmitting}
              mt={3}
              type="submit"
            >
              {buttonText}
            </Button>
            <StructuredContent
              color="text.secondary"
              data={legalText}
              mt={2}
              textAlign="center"
              textStyle="m"
            />
            {formFeedback?.status && <FormFeedback mt={2} {...formFeedback} />}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
