import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  chakra,
} from '@ironhack/design-system2/components';
import { useEffect, useRef, useState } from 'react';
import { Pause, Play, Volume2, VolumeX } from '@ironhack/design-system2/icons';
import { Image } from 'react-datocms';
import { useToggle } from 'react-use';

import type { DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';

type Props = { story: DatoStory };

export const StoryPlayerDesktop = (props: Props): ReactElement => {
  const { story } = props;
  const { userName, userPicture, userRole, company, video } = story;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const PlayIcon = isPlaying ? Pause : Play;
  const [isMuted, toggleIsMuted] = useToggle(false);
  const MuteIcon = isMuted ? VolumeX : Volume2;

  useEffect(() => {
    const handleProgress = () => {
      setProgress((videoRef.current.currentTime / video.video.duration) * 100);
    };

    const handleEnd = () => {
      setIsPlaying(false);
    };

    const videoElement = videoRef.current;
    if (videoElement.paused) {
      setIsPlaying(true);
      void videoElement.play().catch(() => {
        // Prevent error when pause interrupts play https://goo.gl/LdLk22
      });
    }

    videoElement.addEventListener('ended', handleEnd);
    videoElement.addEventListener('timeupdate', handleProgress);

    return () => {
      videoElement.removeEventListener('ended', handleEnd);
      videoElement.removeEventListener('timeupdate', handleProgress);
    };
  }, [video.video.duration]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isPlaying && videoElement.paused) {
      void videoElement.play().catch(() => {
        // Prevent error when pause interrupts play https://goo.gl/LdLk22
      });
    } else if (!isPlaying) {
      void videoElement.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isMuted && !videoElement.muted) {
      videoElement.muted = true;
    } else if (!isMuted) {
      videoElement.muted = false;
    }
  }, [isMuted]);

  return (
    <Box
      _before={{
        backgroundImage:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%), linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 20%)',
        content: '""',
        height: '100%',
        position: 'absolute',
        width: '100%',
      }}
      h="80vh"
      position="relative"
      w="calc(80vh * (9/16))"
    >
      <chakra.video
        autoPlay
        borderRadius="16px"
        h="full"
        id="video"
        objectFit="cover"
        onClick={() => setIsPlaying((prevValue) => !prevValue)}
        ref={videoRef}
        src={video.video.mp4Url}
        w="full"
      />
      <Flex direction="column" position="absolute" px={2} top={2} w="full">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap={0.5}>
            <Box
              background="linear-gradient(#fff, #fff) padding-box, var(--chakra-colors-background-brand) border-box;"
              border="2px solid transparent"
              borderRadius="full"
              maxH="40px"
              maxW="40px"
              overflow="hidden"
            >
              <Image data={userPicture.responsiveImage} />
            </Box>
            <Flex color="text.inverse" direction="column">
              <Text textStyle="mBold">{userName}</Text>
              <Text textStyle="s">{userRole}</Text>
            </Flex>
          </Flex>
          <Box maxW="80px" overflow="hidden">
            <Image data={company.horizontalBackgroundLogo.responsiveImage} />
          </Box>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        bottom={2}
        gap={2}
        justifyContent="flex-end"
        position="absolute"
        px={2}
        w="full"
      >
        <Icon
          as={PlayIcon}
          boxSize="34px"
          color="stroke.white"
          cursor="pointer"
          onClick={() => setIsPlaying((prevValue) => !prevValue)}
        />

        <Progress value={progress} variant="story" w="full" />

        <Icon
          as={MuteIcon}
          boxSize="34px"
          color="stroke.white"
          cursor="pointer"
          onClick={toggleIsMuted}
        />
      </Flex>
    </Box>
  );
};
