import { ComponentType, ReactElement, useState } from 'react';
import {
  Flex,
  HStack,
  NavArrows,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
import { path } from 'ramda';
import Script from 'next/script';

import { CancelWrap, Card, getTextHref } from '@/components';
import { DatoCardBlock, DatoGenericCardBlock } from '@/lib/datocms';

import type { ItemList, ListItem, WithContext } from 'schema-dts';
import type { FlexProps, StackProps } from '@ironhack/design-system2';

export const isDatoCardsArray = (array: unknown[]): array is DatoCardBlock[] =>
  array.every(
    (card): card is DatoCardBlock =>
      typeof (card as DatoCardBlock).title !== 'undefined'
  );

type Props = {
  cardComponent?: ComponentType<{ card: DatoGenericCardBlock } & FlexProps>;
  cards: DatoGenericCardBlock[];
  visibleCards: number;
} & StackProps;

// These values come from the designs
const DESKTOP_WIDTH = 1120;
const CARD_SEPARATION = 16;

export const CardCarousel = (props: Props): ReactElement => {
  const {
    cardComponent: CardComponent = Card,
    cards,
    visibleCards,
    ...stackProps
  } = props;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [currentIndex, setCurrentIndex] = useState(0);

  const hasNext = currentIndex < cards.length - visibleCards;
  const handleNext = () =>
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? prevIndex : prevIndex + 1
    );

  const hasPrev = currentIndex > 0;
  const handlePrev = () =>
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));

  const scrollUnit =
    (DESKTOP_WIDTH - CARD_SEPARATION * (visibleCards - 1)) / visibleCards +
    CARD_SEPARATION;

  const seoStructuredData: WithContext<ItemList> = isDatoCardsArray(cards)
    ? {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: cards.map((card, index): ListItem => {
          const href = getTextHref(path(['button', '0', 'target', '0'], card));
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@id': card.title,
              name: card.title,
              ...(href && { url: href }),
              ...(card.media && { image: card.media?.responsiveImage?.src }),
            },
          };
        }),
      }
    : null;

  return (
    <>
      {cards.length > 0 && (
        <>
          <CancelWrap as={Flex} below="md" withPadding>
            <HStack
              {...stackProps}
              alignItems="flex-start"
              as="ul"
              spacing={2}
              transform={[
                null,
                null,
                `translateX(-${currentIndex * scrollUnit}px)`,
              ]}
              transition="transform 0.5s"
            >
              {cards.map((cardToDisplay, index) => (
                <CardComponent
                  as="li"
                  card={cardToDisplay}
                  h="full"
                  key={`${cardToDisplay.id}-${index}`}
                />
              ))}
            </HStack>
          </CancelWrap>
          {cards.length > visibleCards && !isMobile && (
            <NavArrows
              hasNext={hasNext}
              hasPrev={hasPrev}
              justifyContent="flex-end"
              mt={3}
              onNext={handleNext}
              onPrev={handlePrev}
              w="full"
            />
          )}
          {seoStructuredData && (
            <Script
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(seoStructuredData),
              }}
              id="structured-data-carousel"
              strategy="lazyOnload"
              type="application/ld+json"
            />
          )}
        </>
      )}
    </>
  );
};
