import {
  HStack,
  NavArrows,
  Text,
  useBreakpointValue,
} from '@ironhack/design-system2/components';
// @ts-expect-error -- modifyPath is not yet in @types/ramda
import { find, modifyPath, propEq } from 'ramda';
import { useEffect, useRef, useState } from 'react';

import { ArticleCard, ModuleWrapper } from '@/components';
import type { DatoArticle, DatoBlogRelatedArticlesModule } from '@/lib/datocms';
import { sendEvent } from '@/lib/gtm';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type HighlightedArticlesProps = Pick<
  DatoBlogRelatedArticlesModule,
  'title' | 'buttonText'
> & { articles: DatoArticle[] } & FlexProps;

const events = {
  clickArticle: (title: string): void =>
    sendEvent({
      eventCategory: 'Blog',
      eventAction: 'read related articles',
      eventLabel: title,
    }),
};

// These values come from the designs
const DESKTOP_WIDTH = 1120;
const CARD_SEPARATION = 16;
const VISIBLE_ARTICLES = 4;
const SCROLL_UNIT =
  (DESKTOP_WIDTH - CARD_SEPARATION * (VISIBLE_ARTICLES - 1)) /
    VISIBLE_ARTICLES +
  CARD_SEPARATION;

export const RelatedArticles = (
  props: HighlightedArticlesProps
): ReactElement => {
  const { articles, title, buttonText, ...flexProps } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const hasNext = currentIndex < articles.length - VISIBLE_ARTICLES;
  const handleNext = () =>
    setCurrentIndex((prevIndex) =>
      prevIndex === articles.length - 1 ? prevIndex : prevIndex + 1
    );

  const hasPrev = currentIndex > 0;
  const handlePrev = () =>
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));

  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    stackRef.current?.scrollTo({
      behavior: 'smooth',
      left: currentIndex * SCROLL_UNIT,
    });
  }, [currentIndex]);

  const formattedArticles = articles.map((article) => ({
    author: article.author,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- modifyPath is missing in @types/ramda
    body: modifyPath(
      ['value', 'document', 'children'],
      (c: Array<{ type: string }>) => [find(propEq('type', 'paragraph'), c)],
      article.body
    ),
    buttonText,
    categories: article.categories,
    coverImage: article.coverImage,
    publicationDate: article.publicationDate,
    readingTime: article.readingTime,
    series: article.series,
    slug: article.slug,
    title: article.title,
  }));

  return (
    <ModuleWrapper
      innerProps={{
        direction: 'column',
        gap: 3,
        overflow: 'hidden',
        pr: 0,
      }}
      moduleFormat={{
        spacingBottom: '40',
        spacingTop: '40',
        background: 'light',
      }}
      outerProps={{
        ...flexProps,
      }}
    >
      <Text as="h2" color="text.primary" textStyle={['3xl', null, '4xl']}>
        {title}
      </Text>
      <HStack
        alignItems="flex-start"
        overflowX={['scroll', null, 'auto']}
        ref={stackRef}
        spacing={2}
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {formattedArticles.map((article) => (
          <ArticleCard
            flexShrink={0}
            key={article.slug}
            onClick={(): void => events.clickArticle(article.title)}
            {...article}
            size="l"
          />
        ))}
      </HStack>
      {articles && articles.length > 4 && !isMobile && (
        <NavArrows
          hasNext={hasNext}
          hasPrev={hasPrev}
          justifyContent="flex-end"
          mt={3}
          onNext={handleNext}
          onPrev={handlePrev}
          w="full"
        />
      )}
    </ModuleWrapper>
  );
};
