import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Flex,
  FormFeedback,
  Icon,
  Text,
} from '@ironhack/design-system2/components';
import { ArrowLeft } from '@ironhack/design-system2/icons';
import { isEmpty, omit, pick } from 'ramda';
import { useEffect, useState } from 'react';
import { StructuredTextGraphQlResponse } from 'react-datocms';
import { FormProvider, useForm } from 'react-hook-form';
import { useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  ContactDetailsFields,
  FormSubmit,
  FormThankYou,
  HeroApplyFormWrapper,
  PhoneField,
  SelectField,
} from '@/components';
import { useFormRequest, useGtmLocalStorage, usePageContext } from '@/hooks';
import type {
  DatoBlock,
  DatoButtonBlock,
  DatoCampus,
  DatoCourse,
} from '@/lib/datocms';
import { sendEvent } from '@/lib/gtm';
import {
  commonKeys,
  formatCohortDate,
  regionToCampusMap,
  regionToCountry,
} from '@/lib/utils';
import type { WebCohort } from '@/types';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type FormData = {
  campus: string;
  cohort: string;
  email: string;
  firstName: string;
  format: string;
  lastName: string;
  phone: { phoneNumber: string; country: string };
  track: string;
};

export type HeroApplyForm = DatoBlock<
  {
    buttonText: string;
    campusLabel: string;
    campusPlaceholder: string;
    campusRequiredMessage: string;
    cohortLabel: string;
    cohortPlaceholder: string;
    cohortRequiredMessage: string;
    completeFields: string;
    courseLabel: string;
    coursePlaceholder: string;
    courseRequiredMessage: string;
    emailInvalidMessage: string;
    emailLabel: string;
    emailPlaceholder: string;
    emailRequiredMessage: string;
    errorMessage: string;
    firstNameLabel: string;
    firstNamePlaceholder: string;
    firstNameRequiredMessage: string;
    formatLabel: string;
    formatPlaceholder: string;
    formatRequiredMessage: string;
    formOpenButtonText: string;
    lastNameLabel: string;
    lastNamePlaceholder: string;
    lastNameRequiredMessage: string;
    legalText: StructuredTextGraphQlResponse;
    nextStepButtonText: string;
    noCohortsErrorMessage: string;
    phoneInvalidMessage: string;
    phoneLabel: string;
    phonePlaceholder: string;
    phoneRequiredMessage: string;
    step1: string;
    step2: string;
    step3: string;
    step4Title: string;
    thankYouButton: [DatoButtonBlock];
    thankYouText: string;
    thankYouTitle: string;
    title: string;
  },
  'HeroApplyFormRecord'
>;

type HeroApplyFormData = {
  campuses: Array<Pick<DatoCampus, 'code' | 'name'>>;
  courses: Array<Pick<DatoCourse, 'code' | 'name'>>;
};

type Props = {
  applyForm: HeroApplyForm;
} & BoxProps;

const getSchema = (messages: HeroApplyForm) =>
  z
    .object({
      campus: z.string().min(2, messages.campusRequiredMessage),
      cohort: z.string().min(2, messages.cohortRequiredMessage),
      email: z
        .string({ required_error: messages.emailRequiredMessage })
        .email(messages.emailInvalidMessage),
      firstName: z
        .string({ required_error: messages.firstNameRequiredMessage })
        .trim()
        .min(2, messages.firstNameRequiredMessage),
      format: z.string().min(1, messages.formatRequiredMessage),
      lastName: z
        .string({ required_error: messages.lastNameRequiredMessage })
        .trim()
        .min(2, messages.lastNameRequiredMessage),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phoneRequiredMessage })
              .min(4, messages.phoneInvalidMessage),
            country: z.string({
              required_error: messages.phoneRequiredMessage,
            }),
          },
          {
            invalid_type_error: messages.phoneInvalidMessage,
            required_error: messages.phoneRequiredMessage,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phoneInvalidMessage),
      track: z.string().min(2, messages.courseRequiredMessage),
    })
    .required();

export const HeroApplyForm = (props: Props): ReactElement => {
  const { applyForm, ...boxProps } = props;
  const {
    buttonText,
    campusLabel,
    campusPlaceholder,
    cohortLabel,
    cohortPlaceholder,
    courseLabel,
    coursePlaceholder,
    errorMessage,
    formatLabel,
    formatPlaceholder,
    formOpenButtonText,
    legalText,
    nextStepButtonText,
    noCohortsErrorMessage,
    phoneLabel,
    phonePlaceholder,
    step4Title,
    thankYouButton,
    thankYouText,
    thankYouTitle,
    title,
  } = applyForm;
  const {
    locale,
    pageData: {
      courseSelectorData: { campuses, courses },
      gaCategory,
    },
    params: { language, region },
  } = usePageContext<
    Record<string, never>,
    { courseSelectorData: HeroApplyFormData }
  >();
  const [step, setStep] = useState(1);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const [websiteCampusPage] = useSessionStorage(
    'campus',
    regionToCampusMap[region][0]
  );

  const formSchema = getSchema(applyForm);

  const formMethods = useForm<FormData>({
    defaultValues: {
      campus: '',
      cohort: '',
      format: '',
      phone: { country: regionToCountry(region) },
      track: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    clearErrors,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    setError,
    setValue,
    trigger,
    watch,
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  } | null>();
  const [cohortOptions, setCohortOptions] = useState<
    Array<{ key: string; text: string }>
  >([]);
  const [isFetchingCohorts, setIsFetchingCohorts] = useState(false);
  const campus = watch('campus');
  const isNextStepButtonDisabled =
    isFetchingCohorts || isEmpty(campus) || cohortOptions.length === 0;

  const handleStep1 = async () => {
    const isValid = await trigger(['track', 'format']);
    if (!isValid) return;

    setStep(2);

    const { format, track } = getValues();
    sendEvent({
      course: track,
      eventAction: 'find your course_ step1',
      eventCategory: gaCategory,
      eventLabel: `${track}::${format}`,
      format,
      pageType: gaCategory,
    });
  };

  const handleStep2 = async () => {
    const isValid = await trigger(['campus', 'cohort']);
    if (!isValid) return;

    setStep(3);
    sendEvent({
      campus,
      eventAction: 'find your course_ step2',
      eventCategory: gaCategory,
      eventLabel: campus,
      pageType: gaCategory,
    });
  };

  const goBackOneStep = () => setStep(step - 1);

  const handleMobileOpen = () =>
    sendEvent({
      eventAction: 'click find course steps',
      eventCategory: gaCategory,
      pageType: gaCategory,
    });

  // Clean the selected cohort and cohort options everytime the campus changes
  useEffect(() => {
    setValue('cohort', '');
    setCohortOptions([]);
  }, [setValue, campus]);

  useEffect(() => {
    clearErrors('campus');
    const { track, format } = getValues();

    const findCohorts = async () => {
      setIsFetchingCohorts(true);
      const response = await fetch('/api/cohorts', {
        body: JSON.stringify({
          campus,
          format,
          language,
          region,
          track,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const responseBody = (await response.json()) as { result: WebCohort[] };
      const formattedCohorts = responseBody.result.map((cohort) => {
        const startDateFormatted = formatCohortDate(cohort.start_date, locale);
        const endDateFormatted = formatCohortDate(cohort.end_date, locale);
        return {
          key: cohort.external_ids.salesforce,
          text: `${startDateFormatted} - ${endDateFormatted}`,
        };
      });
      if (formattedCohorts.length === 0) {
        setError('campus', { type: 'custom', message: noCohortsErrorMessage });
      } else {
        setCohortOptions(formattedCohorts);
      }
      setIsFetchingCohorts(false);
    };

    if (track && format && campus) {
      void findCohorts();
    }
  }, [
    campus,
    clearErrors,
    getValues,
    language,
    locale,
    noCohortsErrorMessage,
    region,
    setError,
  ]);
  const formRequest = useFormRequest();
  const [, setGtmLocalStorage] = useGtmLocalStorage();

  const onSubmit = async (formData: FormData): Promise<void> => {
    const action = step === 3 ? 'step3' : 'phone';
    sendEvent({
      eventAction: `find your course_ ${action}`,
      eventCategory: gaCategory,
      pageType: gaCategory,
    });

    setFormFeedback(null);
    try {
      await formRequest({
        data: {
          ...omit(['campus', 'cohort', 'format', 'track'], formData),
          courseId: formData.cohort,
          emailOptIn: true,
          language,
          websiteCampusPage,
        },
        formType: 'apply',
      });

      sendEvent({
        campus: formData.campus,
        course: formData.track,
        email: formData.email,
        eventAction: 'send hero apply form',
        eventCategory: gaCategory,
        eventLabel: `${formData.campus}::${formData.track}::${formData.format}`,
        format: formData.format,
        pageType: gaCategory,
      });
      setGtmLocalStorage({
        applicationData: {
          course: formData.track,
          phoneNumber: formData.phone.phoneNumber,
          ...pick(
            ['campus', 'email', 'firstName', 'format', 'lastName'],
            formData
          ),
        },
        countryName: formData.phone.country,
      });
      setStep(5);
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    }
  };

  return (
    <HeroApplyFormWrapper
      buttonText={formOpenButtonText}
      onFormOpen={handleMobileOpen}
      {...boxProps}
    >
      {step < 4 && (
        <>
          <Text mt={[2, null, 1]} textAlign="center" textStyle="2xl">
            {title}
          </Text>
          <Text
            color="brand.secondary"
            mt={1.5}
            textAlign="center"
            textStyle="mBold"
            textTransform="uppercase"
          >
            {applyForm[`step${step as 1 | 2 | 3}`]}
          </Text>
        </>
      )}
      {step < 5 && (
        <FormProvider {...formMethods}>
          {step > 1 && (
            <Flex
              align="center"
              height={['24px', null, '30px']}
              position="absolute"
              top={3}
            >
              <Icon
                as={ArrowLeft}
                boxSize="24px"
                cursor="pointer"
                onClick={goBackOneStep}
              />
            </Flex>
          )}
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Flex alignItems="center" direction="column">
              {step === 1 && (
                <>
                  <SelectField
                    error={errors.track?.message}
                    isRequired
                    label={courseLabel}
                    mt={[9, null, 5]}
                    name="track"
                    options={courses.map((course) => ({
                      key: course.code,
                      text: course.name,
                    }))}
                    placeholder={coursePlaceholder}
                  />
                  <SelectField
                    error={errors.format?.message}
                    isRequired
                    label={formatLabel}
                    mt={2}
                    name="format"
                    options={['ft', 'pt'].map((format) => ({
                      key: format,
                      text: commonKeys[language][format],
                    }))}
                    placeholder={formatPlaceholder}
                  />
                  <Button mt={3} onClick={handleStep1} w="full">
                    {nextStepButtonText}
                  </Button>
                </>
              )}
              {step === 2 && (
                <>
                  <SelectField
                    error={errors.campus?.message}
                    isRequired
                    label={campusLabel}
                    mt={[9, null, 5]}
                    name="campus"
                    options={campuses.map(({ code, name }) => ({
                      key: code,
                      text: name,
                    }))}
                    placeholder={campusPlaceholder}
                  />
                  <SelectField
                    error={errors.cohort?.message}
                    isDisabled={isFetchingCohorts || cohortOptions.length === 0}
                    isRequired
                    label={cohortLabel}
                    mt={2}
                    name="cohort"
                    options={cohortOptions}
                    placeholder={cohortPlaceholder}
                  />
                  <Button
                    disabled={isNextStepButtonDisabled}
                    isLoading={isFetchingCohorts}
                    mt={3}
                    onClick={handleStep2}
                    w="full"
                  >
                    {nextStepButtonText}
                  </Button>
                </>
              )}
              {step === 3 && (
                <ContactDetailsFields
                  fields={pick(
                    [
                      'completeFields',
                      'emailLabel',
                      'emailPlaceholder',
                      'firstNameLabel',
                      'firstNamePlaceholder',
                      'lastNameLabel',
                      'lastNamePlaceholder',
                      'phoneLabel',
                      'phonePlaceholder',
                    ],
                    applyForm
                  )}
                  isSmall
                  mt={[9, null, 5]}
                  onGoogleClick={() => {
                    setFormFeedback(null);
                    setIsGoogleSubmitting(true);
                  }}
                  onGoogleError={() => {
                    setIsGoogleSubmitting(false);
                    setFormFeedback({
                      status: 'error',
                      message: errorMessage,
                    });
                  }}
                  onGoogleSuccess={async () => {
                    setIsGoogleSubmitting(false);
                    const isValid = await trigger([
                      'email',
                      'firstName',
                      'lastName',
                    ]);
                    if (isValid) setStep(4);
                  }}
                  withPhone
                />
              )}
              {step === 4 && (
                <>
                  <Text
                    mt={[3, null, 7]}
                    textAlign="start"
                    textStyle="lBold"
                    width="full"
                  >
                    {step4Title}
                  </Text>
                  <PhoneField
                    error={
                      errors.phone?.message ||
                      errors.phone?.phoneNumber?.message
                    }
                    isRequired
                    label={phoneLabel}
                    mt={2}
                    name="phone"
                    placeholder={phonePlaceholder}
                  />
                </>
              )}
              {step > 2 && (
                <FormSubmit
                  buttonProps={{ width: 'full' }}
                  buttonText={buttonText}
                  isLoading={isSubmitting || isGoogleSubmitting}
                  legalText={legalText}
                  mt={2}
                />
              )}
              {formFeedback?.status && (
                <FormFeedback mt={2} {...formFeedback} />
              )}
            </Flex>
          </form>
        </FormProvider>
      )}
      {step === 5 && (
        <FormThankYou
          button={thankYouButton}
          buttonProps={{ mt: [4, null, 3], w: 'full' }}
          mt={[0, null, 1]}
          text={thankYouText}
          textAlign="center"
          title={thankYouTitle}
        />
      )}
    </HeroApplyFormWrapper>
  );
};

export const heroApplyFormFragment = `
  fragment heroApplyFormFragment on HeroApplyFormRecord {
    __typename
    buttonText
    campusLabel
    campusPlaceholder
    campusRequiredMessage
    cohortLabel
    cohortPlaceholder
    cohortRequiredMessage
    completeFields
    courseLabel
    coursePlaceholder
    courseRequiredMessage
    emailInvalidMessage
    emailLabel
    emailPlaceholder
    emailRequiredMessage
    errorMessage
    firstNameLabel
    firstNamePlaceholder
    firstNameRequiredMessage
    formatLabel
    formatPlaceholder
    formatRequiredMessage
    formOpenButtonText
    lastNameLabel
    lastNamePlaceholder
    lastNameRequiredMessage
    legalText {
      value
    }
    nextStepButtonText
    noCohortsErrorMessage
    phoneInvalidMessage
    phoneLabel
    phonePlaceholder
    phoneRequiredMessage
    step1
    step2
    step3
    step4Title
    thankYouButton {
      ...buttonFragment
    }
    thankYouText
    thankYouTitle
    title
  }
`;
