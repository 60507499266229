import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Image } from 'react-datocms';
import { nopeResolver } from '@hookform/resolvers/nope';
import { Nope } from 'nope-validator';
import {
  Box,
  Button,
  Card,
  CardProps,
  Checkbox,
  FormFeedback,
  FormField,
  Input,
  Text,
  VStack,
} from '@ironhack/design-system2/components';

import type { DatoBlogAd } from '@/lib/datocms';
import { StructuredContent } from '@/components';
import { sendEvent } from '@/lib/gtm';

import type { StructuredTextGraphQlResponse } from 'react-datocms';

type Props = Omit<CardProps, 'content'> & DatoBlogAd;

type FormInputs = {
  email: string;
  newsletter: boolean;
  legal: boolean;
};

const eventBase = { eventCategory: 'blog', eventAction: 'subscribe' };
const events = {
  subscribeClick: (title: string): void =>
    sendEvent({
      ...eventBase,
      eventLabel: `post - click widget - ${title}`,
    }),
  subscribeSuccess: (title: string): void =>
    sendEvent({
      ...eventBase,
      eventLabel: `post - successfuly suscribed widget - ${title}`,
    }),
  subscribeFail: (title: string): void =>
    sendEvent({
      ...eventBase,
      eventLabel: `post - subscribed fail widget - ${title}`,
    }),
};

const getSchema = (
  messages: Record<string, { invalid?: string; required?: string }>
) =>
  Nope.object().shape({
    email: Nope.string()
      .email(messages.email.invalid)
      .required(messages.email.required),
    legal: Nope.boolean().oneOf([true], messages.legal.required),
  });

export const BlogAd = (props: Props): ReactElement => {
  const {
    title,
    content,
    buttonText,
    banner,
    inputs,
    pardotUrl,
    submitError,
    submitSuccess,
    ...flexProps
  } = props;
  const [emailField, newsletterField, legalField] = inputs;
  const formSchema = getSchema({
    email: {
      invalid: emailField.invalidMessage,
      required: emailField.requiredMessage,
    },
    legal: { required: legalField.requiredMessage },
  });
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<FormInputs>({
    defaultValues: { newsletter: false, legal: false },
    mode: 'onChange',
    resolver: nopeResolver(formSchema),
  });
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error' | 'success';
    message: string;
  } | null>();

  const onCtaClick = async (data: {
    email: string;
    Email_Opt_In: boolean;
  }): Promise<void> => {
    const response = await fetch('/api/pardot/', {
      body: JSON.stringify({ data, pardotUrl }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });

    if ([400, 500].includes(response.status)) {
      throw new Error('Sending form to pardot error.');
    }
  };

  const onSubmitHandler = async (data: FormInputs): Promise<void> => {
    events.subscribeClick(document.title);
    setFormFeedback(null);
    const pardotData = { email: data.email, Email_Opt_In: data.newsletter };
    try {
      await onCtaClick(pardotData);
      events.subscribeSuccess(document.title);
      setFormFeedback({
        status: 'success',
        message: submitSuccess,
      });
      reset();
    } catch {
      events.subscribeFail(document.title);
      setFormFeedback({
        status: 'error',
        message: submitError,
      });
    }
  };

  return (
    <Card gap={2} p={2} pb={4} shadow="l" size="2xl" {...flexProps}>
      <Box borderRadius={8} overflow="hidden" w="full">
        <Image data={banner.responsiveImage} objectFit="cover" />
      </Box>
      <Text color="text.primary" textStyle="xlBold">
        {title}
      </Text>
      <StructuredContent
        color="text.primary"
        data={content as StructuredTextGraphQlResponse}
        textStyle="m"
      />
      <VStack
        as="form"
        gap={2}
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <FormField
          error={errors.email?.message as string}
          isInvalid={Boolean(errors.email)}
          isRequired
          name="email"
        >
          <Input
            placeholder={emailField.placeholder}
            type={emailField.inputType}
            {...register('email', { required: true })}
          />
        </FormField>
        <FormField
          error={errors.legal?.message as string}
          isInvalid={Boolean(errors.legal)}
          isRequired
          name="legal"
        >
          <Controller
            control={control}
            defaultValue={false}
            name="legal"
            render={({ field: { onBlur, onChange, value, ref } }) => (
              <Checkbox
                isChecked={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
              >
                <StructuredContent
                  data={legalField.labelNew as StructuredTextGraphQlResponse}
                  textStyle="m"
                />
              </Checkbox>
            )}
            rules={{ required: true }}
          />
        </FormField>
        <FormField
          error={errors.newsletter?.message as string}
          isInvalid={Boolean(errors.newsletter)}
          isRequired
          name="newsletter"
        >
          <Controller
            control={control}
            defaultValue={false}
            name="newsletter"
            render={({ field: { onBlur, onChange, value, ref } }) => (
              <Checkbox
                isChecked={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
              >
                <StructuredContent
                  data={
                    newsletterField.labelNew as StructuredTextGraphQlResponse
                  }
                  textStyle="m"
                />
              </Checkbox>
            )}
          />
        </FormField>
        <Button
          disabled={!isValid}
          isLoading={isSubmitting}
          type="submit"
          variant="primary"
          width="full"
        >
          {buttonText}
        </Button>
        {formFeedback?.status && <FormFeedback {...formFeedback} />}
      </VStack>
    </Card>
  );
};
