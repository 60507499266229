import { Flex, Select, Text } from '@ironhack/design-system2/components';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { anyPass, isEmpty, isNil, reject } from 'ramda';

import { sendEvent } from '@/lib/gtm';
import type { DatoBlogCategory } from '@/lib/datocms';
import { usePageContext } from '@/hooks';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';

type QueryParams = { course: string; category: string };
type Filter = {
  placeholder: string;
  options: DatoBlogCategory[];
  value?: string;
  show?: boolean;
};
type Props = {
  title: string;
  filters: { category?: Filter; course?: Filter };
} & FlexProps;

export const ArticlesFilter = (props: Props): ReactElement => {
  const { title, filters, ...flexProps } = props;
  const router = useRouter<QueryParams>();
  const { region, language, category, course, pathParts } = router.query;
  const categoryFilter = category || filters.category?.value || '';
  const courseFilter = course || filters.course?.value || '';
  const {
    pageData: { gaCategory },
  } = usePageContext();

  const handleCourseFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      sendEvent({
        eventCategory: gaCategory,
        eventAction: 'filter course',
        eventLabel: value,
      });
      void router.push(
        {
          pathname: '/[region]/[language]/[[...pathParts]]',
          query: {
            region,
            language,
            pathParts: [pathParts[0]],
            ...reject(anyPass([isEmpty, isNil]), {
              course: value,
              category: filters.category.show ? categoryFilter : '',
            }),
          },
        },
        null,
        { shallow: true }
      );
    },
    [
      categoryFilter,
      filters.category.show,
      gaCategory,
      language,
      pathParts,
      region,
      router,
    ]
  );

  const handleCategoryFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      sendEvent({
        eventCategory: gaCategory,
        eventAction: 'filter category',
        eventLabel: value,
      });
      void router.push(
        {
          pathname: '/[region]/[language]/[[...pathParts]]',
          query: {
            region,
            language,
            pathParts: [pathParts[0]],
            ...reject(anyPass([isEmpty, isNil]), {
              course: filters.course.show ? courseFilter : '',
              category: value,
            }),
          },
        },
        null,
        { shallow: true }
      );
    },
    [
      courseFilter,
      filters.course.show,
      gaCategory,
      language,
      pathParts,
      region,
      router,
    ]
  );

  return (
    <Flex
      alignItems={['flex-start', null, 'center']}
      borderRadius="8px"
      direction={['column', null, 'row']}
      gap={[3, null, 0]}
      justify="space-between"
      w="full"
      {...flexProps}
    >
      <Text as="h2" color="text.primary" textStyle="4xl">
        {title}
      </Text>
      <Flex
        flexWrap={['wrap', null, 'nowrap']}
        gap={1}
        w={['full', null, '50%']}
      >
        {filters.course.show && (
          <Select
            onChange={handleCourseFilterChange}
            placeholder={filters.course.placeholder}
            value={courseFilter}
          >
            {filters.course.options.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
        )}
        {filters.category.show && (
          <Select
            onChange={handleCategoryFilterChange}
            placeholder={filters.category.placeholder}
            value={categoryFilter}
          >
            {filters.category.options.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>
        )}
      </Flex>
    </Flex>
  );
};
