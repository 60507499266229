import {
  AspectRatio,
  Box,
  Flex,
  Link,
  ListItem,
  OrderedList,
  Skeleton,
  Text,
  UnorderedList,
} from '@ironhack/design-system2/components';
import {
  isBlockquote,
  isHeading,
  isLink,
  isList,
  isListItem,
  isParagraph,
} from 'datocms-structured-text-utils';
import React from 'react';
import {
  Image,
  StructuredText,
  renderMarkRule,
  renderRule,
} from 'react-datocms';
import ReactPlayer from 'react-player/lazy';

import { Button, ListWithIcons } from '@/components';
import type {
  DatoButtonBlock,
  DatoCustomBlock,
  DatoImageBlock,
  DatoListWithIconsBlock,
  DatoVideoBlock,
} from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

type Props = FlexProps & {
  color?: FlexProps['color'];
  data: StructuredTextGraphQlResponse;
  textStyle?: string;
};

const headingStyleMap = {
  1: '6xl',
  2: '5xl',
  3: '4xl',
  4: '3xl',
  5: '2xl',
  6: 'xl',
};

export const StructuredContent = (props: Props): React.ReactElement => {
  const {
    data,
    textStyle = 'l',
    color = 'text.primary',
    noOfLines,
    ...flexProps
  } = props;
  const renderBlock = (record: DatoCustomBlock): React.ReactElement | null => {
    switch (record.__typename) {
      case 'ButtonRecord': {
        return (
          <Flex alignSelf="center" my={3}>
            <Button {...(record as DatoButtonBlock)} />
          </Flex>
        );
        break;
      }
      case 'ImageBlockRecord':
        return (
          <Flex alignSelf="center" my={3}>
            <Image
              data={(record as DatoImageBlock).image.responsiveImage}
              style={{ borderRadius: '16px' }}
            />
          </Flex>
        );
        break;
      case 'ListWithIconsBlockRecord':
        return (
          <ListWithIcons
            color={color}
            list={record as DatoListWithIconsBlock}
          />
        );
        break;
      case 'VideoBlockRecord': {
        const { videoUrl } = record as DatoVideoBlock;
        return (
          <Flex justifyContent="center" my={3}>
            <AspectRatio flex={1} maxW="800px" ratio={16 / 9}>
              {/* @ts-expect-error: Not sure why this one is being marked as an error. Maybe something with the import? */}
              <ReactPlayer
                controls
                fallback={<Skeleton h="100%" />}
                height="100%"
                light
                style={{ borderRadius: '16px' }}
                url={videoUrl}
                width="100%"
              />
            </AspectRatio>
          </Flex>
        );
        break;
      }
      default:
        return null;
    }
  };

  return (
    <Flex direction="column" gap={1} w="full" {...flexProps}>
      <StructuredText
        customMarkRules={[
          renderMarkRule('strong', ({ children, key }) => (
            <Text
              as="strong"
              color={color}
              key={key}
              textStyle={`${textStyle}Bold`}
              {...(noOfLines ? { noOfLines } : {})}
            >
              {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
              {children}
            </Text>
          )),
        ]}
        customRules={[
          renderRule(isLink, ({ key, children, node }) => {
            const isExternal = node.meta?.some(
              (m) => m.id === 'target' && m.value === '_blank'
            );
            return (
              <Link href={node.url} isExternal={isExternal} key={key}>
                <Text as="span" textStyle={textStyle}>
                  {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
                  {children}
                </Text>
              </Link>
            );
          }),
          renderRule(isParagraph, ({ children, key }) => (
            <Text
              color={color}
              key={key}
              textStyle={textStyle}
              {...(noOfLines ? { noOfLines } : {})}
            >
              {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
              {children}
            </Text>
          )),
          renderRule(isBlockquote, ({ children, key }) => (
            <Text
              borderLeftColor="stroke.interactive"
              borderLeftWidth="4px"
              key={key}
              pl={1.5}
            >
              {React.Children.map(
                /* @ts-expect-error: Not sure why this one is being marked as an error. */
                children,
                (child: React.ReactElement) =>
                  React.cloneElement(child, {
                    textStyle: 'xl',
                  })
              )}
            </Text>
          )),
          renderRule(isHeading, ({ node, children, key }) => (
            <Text
              as={`h${node.level}`}
              color={color}
              key={key}
              my={3}
              textStyle={headingStyleMap[node.level]}
            >
              {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
              {children}
            </Text>
          )),
          renderRule(isList, ({ node, children, key }) => (
            <Box key={key}>
              {node?.style === 'numbered' ? (
                <OrderedList mb={3} spacing={0}>
                  {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
                  {children}
                </OrderedList>
              ) : (
                <UnorderedList mb={3} spacing={0}>
                  {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
                  {children}
                </UnorderedList>
              )}
            </Box>
          )),
          renderRule(isListItem, ({ children, key }) => (
            <ListItem key={key}>
              {/* @ts-expect-error: Not sure why this one is being marked as an error. */}
              {children}
            </ListItem>
          )),
        ]}
        data={data}
        // @ts-expect-error -- Don't know how to type this. Feel free to check.
        renderBlock={({
          record,
        }: {
          record: DatoCustomBlock;
        }): ReturnType<typeof renderBlock> => renderBlock(record)}
      />
    </Flex>
  );
};
