import {
  Box,
  Button,
  Flex,
  Hide,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Show,
} from '@ironhack/design-system2/components';
import { X } from '@ironhack/design-system2/icons';
import { useToggle } from 'react-use';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement, ReactNode } from 'react';

type Props = {
  buttonText: string;
  children: ReactNode;
  onFormOpen?: () => void;
} & BoxProps;

export const HeroApplyFormWrapper = (props: Props): ReactElement => {
  const { buttonText, children, onFormOpen, ...boxProps } = props;
  const [showForm, toggleForm] = useToggle(false);

  const handleClick = () => {
    toggleForm();
    if (onFormOpen) onFormOpen();
  };

  return (
    <>
      <Hide above="md">
        <Box bg="background.white" padding={2} textAlign="center" {...boxProps}>
          <Button onClick={handleClick} width={['full', '70%']}>
            {buttonText}
          </Button>
        </Box>
        <Modal isOpen={showForm} onClose={toggleForm} size="full">
          <ModalContent
            bg="background.light"
            position="relative"
            pt={1}
            rounded="none"
          >
            <ModalHeader align="center" as={Flex} justify="end" padding={2}>
              <Icon
                as={X}
                boxSize="24px"
                cursor="pointer"
                onClick={toggleForm}
              />
            </ModalHeader>
            <ModalBody pb={5} pt={0} px={2}>
              {children}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Hide>
      <Show above="md">
        <Flex
          as="section"
          bg="background.white"
          borderRadius="lg"
          color="text.primary"
          direction="column"
          padding={2}
          position="relative"
          width={[null, null, '440px', '500px']}
          {...boxProps}
        >
          {children}
        </Flex>
      </Show>
    </>
  );
};
