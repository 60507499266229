import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  forwardRef,
  useRadioGroup,
} from '@ironhack/design-system2/components';
import { partition, propEq } from 'ramda';

import { CancelWrap, CohortSelectorCard } from '@/components';
import { usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import { commonKeys } from '@/lib/utils';
import type { WebCohort } from '@/types';

import type {
  FlexProps,
  UseRadioGroupProps,
} from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

export type CohortSelectorProps = {
  cohorts: WebCohort[];
  radioGroupProps: UseRadioGroupProps;
} & Omit<FlexProps, keyof UseRadioGroupProps>;

export const CohortSelector = forwardRef<CohortSelectorProps, 'input'>(
  (props: CohortSelectorProps, ref): ReactElement => {
    const { cohorts, radioGroupProps, ...flexProps } = props;
    const { getRadioProps, getRootProps, isDisabled } =
      useRadioGroup(radioGroupProps);
    const {
      params: { language },
      pageData: { gaCategory, gaPageType },
    } = usePageContext();

    const [ftCohorts, ptCohorts] = partition<WebCohort>(propEq('format', 'ft'))(
      cohorts
    ) as [WebCohort[], WebCohort[]];
    const tabs = [
      { code: 'ft', cohorts: ftCohorts, title: commonKeys[language].ft },
      { code: 'pt', cohorts: ptCohorts, title: commonKeys[language].pt },
    ].filter((tab) => tab.cohorts.length > 0);

    return (
      <Tabs width="full">
        <TabList gap={3} mb={3}>
          {tabs.map(({ code: formatCode, title: tabTitle }, index) => (
            <Tab
              key={`${tabTitle}-${index}`}
              onClick={() => {
                sendEvent({
                  eventAction: 'click format',
                  eventCategory: gaCategory,
                  eventLabel: formatCode,
                  pageType: gaPageType,
                });
              }}
              textTransform="uppercase"
            >
              {tabTitle}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map(({ title: tabTitle, cohorts: cohortList }, index) => (
            <TabPanel key={`${tabTitle}-${index}`} p={0}>
              <CancelWrap my={-0.5} py={0.5} withPadding withScrollbar>
                <Flex
                  {...getRootProps()}
                  alignItems="center"
                  flexDirection={['column', null, 'row']}
                  gap={2}
                  minWidth={['100%', null, 'auto']}
                  ref={ref}
                  {...flexProps}
                >
                  {cohortList.map((cohort) => (
                    <CohortSelectorCard
                      cohort={cohort}
                      isDisabled={isDisabled}
                      key={cohort.id}
                      {...getRadioProps({
                        value: cohort.external_ids.salesforce,
                      })}
                    />
                  ))}
                </Flex>
              </CancelWrap>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  }
);
