import { ReactElement } from 'react';
import { Checkbox, FormField, Text } from '@ironhack/design-system2/components';
import { Controller, useFormContext } from 'react-hook-form';

import { StructuredContent } from '@/components';

import type { FormControlProps } from '@ironhack/design-system2/components';
import type { StructuredTextGraphQlResponse } from 'react-datocms';

type Props = {
  color?: FormControlProps['color'];
  error?: string;
  isRequired: boolean;
  label: string | StructuredTextGraphQlResponse;
  name: string;
} & Omit<FormControlProps, 'label'>;

export const CheckboxField = (props: Props): ReactElement => {
  const { color, error, isRequired, label, name, ...formFieldProps } = props;
  const { control } = useFormContext();
  return (
    <FormField
      error={error as string}
      isInvalid={Boolean(error)}
      isRequired={isRequired}
      name={name}
      {...formFieldProps}
    >
      <Controller
        control={control}
        defaultValue={false}
        name={name}
        render={({ field: { onBlur, onChange, value, ref } }) => (
          <Checkbox
            isChecked={value as boolean}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          >
            {typeof label === 'string' ? (
              <Text color={color} textStyle="m">
                {label}
              </Text>
            ) : (
              <StructuredContent
                color={color}
                data={label}
                textAlign="center"
                textStyle="m"
              />
            )}
          </Checkbox>
        )}
        rules={{ required: isRequired }}
      />
    </FormField>
  );
};
