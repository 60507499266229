import {
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb as DsBreadcrumb,
  Icon,
} from '@ironhack/design-system2/components';
import { useRouter } from 'next/router';
import { ChevronRight } from '@ironhack/design-system2/icons';
import NextLink from 'next/link';
import { drop, head, pipe, range, split, take, takeWhile } from 'ramda';
import Script from 'next/script';

import { ModuleWrapper } from '@/components';
import { toTitleCase } from '@/lib/utils';
import config from '@/config';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2';
import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

type Props = FlexProps;

export const Breadcrumb = (props: Props): ReactElement => {
  const { ...flexProps } = props;
  const { asPath } = useRouter();

  const ignorePaginatedPages = takeWhile(
    (part: string) => !['page', 'c', 'e'].includes(part)
  );

  const pathParts = pipe(
    split('?'),
    head,
    split('/'),
    drop(3),
    ignorePaginatedPages
  )(asPath);

  const basePath = asPath.slice(0, 6);
  const breadcrumbs = [
    {
      route: basePath,
      title: 'Ironhack',
    },
    ...range(1, pathParts.length + 1).map((index) => ({
      route: `${basePath}/${take(index, pathParts).join('/')}`,
      title: toTitleCase(pathParts[index - 1]).replace('#', ''),
    })),
  ];

  const seoStructuredData: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(
      (breadcrumb, index): ListItem => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': `${config.baseUrl}${breadcrumb.route}`,
          name: breadcrumb.title,
        },
      })
    ),
  };

  return (
    <ModuleWrapper
      moduleFormat={{
        background: 'transparent',
        spacingTop: '16',
        spacingBottom: '16',
      }}
      outerProps={{
        bg: 'fill.preFooter',
        ...flexProps,
      }}
    >
      <DsBreadcrumb
        separator={
          <Icon as={ChevronRight} boxSize={6} color="text.inverse" mt={0.5} />
        }
      >
        {breadcrumbs.map(({ route, title }, index) => (
          <BreadcrumbItem
            color="text.inverse"
            isCurrentPage={index === breadcrumbs.length - 1}
            key={route}
          >
            {index === breadcrumbs.length - 1 ? (
              <span aria-current="page">{title}</span>
            ) : (
              <BreadcrumbLink as={NextLink} href={route} passHref>
                {title}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
      </DsBreadcrumb>
      <Script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(seoStructuredData),
        }}
        id="structured-data-breadcrumb"
        strategy="lazyOnload"
        type="application/ld+json"
      />
    </ModuleWrapper>
  );
};
