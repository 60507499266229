import { UserCard as DsUserCard } from '@ironhack/design-system2/components';
import { Image } from 'react-datocms';

import type { DatoUserCardBlock } from '@/lib/datocms';

import type { CardProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type Size = 'xs' | 'l' | 'xl' | '2xl' | '3xl';
type Props = {
  card: DatoUserCardBlock;
  size?: Size;
} & Omit<CardProps, 'size'>;

export const UserCard = (props: Props): ReactElement => {
  const {
    card: { image, logos = [], ...card },
    size = 'l',
    ...cardProps
  } = props;

  const logoElements = logos.map(({ responsiveImage }) => (
    <Image data={responsiveImage} key={responsiveImage.src} />
  ));
  return (
    <DsUserCard
      image={<Image data={image.responsiveImage} />}
      logos={logoElements}
      size={size}
      {...card}
      {...cardProps}
      {...(cardProps.bg !== 'transparent' && { color: 'text.primary' })}
    />
  );
};
