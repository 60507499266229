import { ReactElement } from 'react';
import { FormField, Textarea } from '@ironhack/design-system2/components';
import { useFormContext } from 'react-hook-form';

import type { FormControlProps } from '@ironhack/design-system2/components';

type Props = {
  label: string;
  error?: string;
  name: string;
  placeholder: string;
  isRequired: boolean;
} & FormControlProps;

export const TextareaField = (props: Props): ReactElement => {
  const { label, error, name, placeholder, isRequired, ...formFieldProps } =
    props;
  const { register } = useFormContext();

  return (
    <FormField
      error={error}
      isInvalid={Boolean(error)}
      isRequired={isRequired}
      label={label}
      name={name}
      {...formFieldProps}
    >
      <Textarea
        placeholder={placeholder}
        {...register(name, { required: isRequired })}
      />
    </FormField>
  );
};
