import {
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  Text,
} from '@ironhack/design-system2/components';
import { ReactElement } from 'react';
import { Image } from 'react-datocms';
import NextLink from 'next/link';
import { ChevronLeft } from '@ironhack/design-system2/icons';

import { DatoArticle, DatoBlogArticleHeaderModule } from '@/lib/datocms';
import { ModuleWrapper } from '@/components';
import { usePageContext } from '@/hooks';

type Props = DatoArticle & DatoBlogArticleHeaderModule;

export const ArticleHeader = (props: Props): ReactElement => {
  const {
    author,
    backButtonText,
    categories,
    coverImage,
    publicationDate,
    readingTime,
    series,
    subtitle,
    title,
  } = props;
  const {
    params: { region, language },
  } = usePageContext();

  // Temporary ÑAPA: don't link to other English blogs
  const blogHomeRegion =
    language === 'en' && !['us', 'uk', 'ww'].includes(region) ? 'us' : region;

  return (
    <ModuleWrapper
      innerProps={{
        align: 'center',
        justify: 'space-between',
        direction: 'column',
      }}
      moduleFormat={{
        spacingBottom: '64',
        spacingTop: '0',
        background: 'transparent',
      }}
      outerProps={{ as: 'header' }}
    >
      <Button
        _hover={{
          color: 'link.inverse',
          textDecoration: 'underline',
        }}
        alignSelf="start"
        as={NextLink}
        color="link.inverse"
        fontSize="14px"
        href={{
          pathname: '/[region]/[language]/[[...pathParts]]',
          query: { region: blogHomeRegion, language, pathParts: ['blog'] },
        }}
        // @ts-expect-error -- Something is wrong in the DS button types. Feel free to check.
        leftIcon={ChevronLeft}
        passHref
        size="s"
        variant="tertiary"
      >
        {backButtonText}
      </Button>
      <Flex mb={[4, null, 8]} mt={[3, null, 6]}>
        <Box color="text.inverse" mr={[0, null, 4]}>
          {series && <Tag colorScheme="pink" text={series.name} />}
          <Text mt={2} textStyle="m">
            {publicationDate}
            {readingTime ? ` - ${readingTime}` : ''}
          </Text>
          <Text as="h1" mt={2} noOfLines={3} textStyle={['3xl', null, '5xl']}>
            {title}
          </Text>
          <Text mt={2} noOfLines={3} textStyle={['xl', null, '2xl']}>
            {subtitle}
          </Text>
          <Box display={['block', null, 'none']} mt={2} w="full">
            <Image data={coverImage.responsiveImage} />
          </Box>
          <Stack
            align="center"
            bg="background.white"
            borderRadius={8}
            direction="row"
            mt={[2, null, 4]}
            px={1.5}
            py={0.5}
            spacing={2}
            width={['full', null, 'fit-content']}
          >
            {author.photo && <Image data={author.photo.responsiveImage} />}
            <Text color="text.primary" textStyle="m">
              {author.name}
              {author.jobTitle ? ` - ${author.jobTitle}` : ''}
            </Text>
          </Stack>
          <Stack direction="row" mt={[2, null, 3]} spacing={1}>
            {categories.map(({ name }) => (
              <Tag colorScheme="dark" key={name} text={name} />
            ))}
          </Stack>
        </Box>
        <Box display={['none', null, 'block']} minW="35rem">
          <Image data={coverImage.responsiveImage} />
        </Box>
      </Flex>
    </ModuleWrapper>
  );
};
