import {
  Button,
  LinkBox,
  LinkOverlay,
  SuperCard,
} from '@ironhack/design-system2/components';
import { ChevronRight } from '@ironhack/design-system2/icons';
import NextLink from 'next/link';
import { take } from 'ramda';
import { Image } from 'react-datocms';

import type { DatoArticle } from '@/lib/datocms';
import { StructuredContent } from '@/components';
import { usePageContext } from '@/hooks';

import type { FlexProps } from '@ironhack/design-system2/components';
import type { ReactElement } from 'react';

type Props = {
  buttonText: string;
  size: 'l' | '2xl' | '3xl';
} & Omit<DatoArticle, 'previewUrl'> &
  FlexProps;

export const ArticleCard = (props: Props): ReactElement => {
  const {
    author,
    buttonText,
    categories,
    body,
    coverImage,
    publicationDate,
    readingTime,
    series,
    slug,
    title,
    size,
    ...flexProps
  } = props;
  const {
    params: { language, region },
  } = usePageContext();

  const pathPattern = '/[region]/[language]/blog/[slug]';

  return (
    <SuperCard as={LinkBox} size={size} {...flexProps}>
      <SuperCard.Header>
        <SuperCard.Media>
          <Image data={coverImage.responsiveImage} />
        </SuperCard.Media>
        <SuperCard.MediaBadges
          {...(series && { primary: { text: series.name } })}
          {...(readingTime && { secondary: { text: readingTime } })}
        />
      </SuperCard.Header>
      <SuperCard.Body>
        <NextLink
          href={{ pathname: pathPattern, query: { region, language, slug } }}
          legacyBehavior
          passHref
        >
          <LinkOverlay>
            <SuperCard.Title as="h3">{title}</SuperCard.Title>
          </LinkOverlay>
        </NextLink>
        <SuperCard.Note>{`${author.name} - ${publicationDate}`}</SuperCard.Note>
        <SuperCard.Tags tags={take(4, categories).map((c) => c.name)} />
        <SuperCard.Content>
          <StructuredContent data={body} noOfLines={2} />
        </SuperCard.Content>
      </SuperCard.Body>
      <SuperCard.Actions>
        <NextLink
          href={{ pathname: pathPattern, query: { region, language, slug } }}
          legacyBehavior
          passHref
        >
          <Button
            rightIcon={ChevronRight}
            role="presentation"
            size="s"
            variant="secondary"
            w="full"
          >
            {buttonText}
          </Button>
        </NextLink>
      </SuperCard.Actions>
    </SuperCard>
  );
};
