import { Flex, HStack } from '@ironhack/design-system2/components';
import { ReactElement, useState } from 'react';

import { CancelWrap, StoryPlayer, StoryThumbnail } from '@/components';
import type { DatoStory } from '@/lib/datocms';

import type { FlexProps } from '@ironhack/design-system2/components';

type Props = {
  stories: DatoStory[];
  onStoryView: (userName: string) => void;
} & FlexProps;

export const StoryList = (props: Props): ReactElement => {
  const { stories, onStoryView, ...flexProps } = props;
  const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(-1);

  const handlePreviousStory = () => {
    setCurrentStoryIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? prevIndex : prevIndex - 1;
      if (newIndex !== prevIndex) onStoryView(stories[newIndex].userName);
      return newIndex;
    });
  };

  const handleNextStory = () => {
    setCurrentStoryIndex((prevIndex) => {
      const newIndex =
        prevIndex === stories.length - 1 ? prevIndex : prevIndex + 1;
      if (newIndex !== prevIndex) onStoryView(stories[newIndex].userName);
      return newIndex;
    });
  };

  const handleCloseStory = () => {
    setCurrentStoryIndex(-1);
  };

  return (
    <>
      <CancelWrap
        alignSelf="normal"
        as={Flex}
        below="md"
        justifyContent={[null, null, 'center']}
        withPadding
        {...flexProps}
      >
        <HStack alignItems="flex-start" as="ul" justify="center" spacing={1}>
          {stories.map((story, index) => (
            <StoryThumbnail
              as="li"
              cursor="pointer"
              key={`${story.userName}-${index}`}
              onClick={() => {
                setCurrentStoryIndex(index);
                onStoryView(stories[index].userName);
              }}
              story={story}
            />
          ))}
        </HStack>
      </CancelWrap>
      {currentStoryIndex >= 0 && (
        <StoryPlayer
          onExit={handleCloseStory}
          onNext={
            currentStoryIndex < stories.length - 1 ? handleNextStory : null
          }
          onPrevious={currentStoryIndex > 0 ? handlePreviousStory : null}
          story={stories[currentStoryIndex]}
        />
      )}
    </>
  );
};
