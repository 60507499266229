import { Box } from '@ironhack/design-system2/components';
import { append, equals, negate, pipe, take, when } from 'ramda';
import { Children, cloneElement } from 'react';

import { WRAPPER_PADDING } from '@/components';

import type { BoxProps } from '@ironhack/design-system2/components';
import type { ReactElement, ReactNode } from 'react';

const sizes = ['sm', 'md', 'lg'] as const;

type Props = {
  below?: typeof sizes[number];
  children?: ReactNode;
  withPadding?: boolean;
  withScrollbar?: boolean;
} & BoxProps;

export const CancelWrap = (props: Props): ReactElement => {
  const {
    below,
    children,
    withPadding = false,
    withScrollbar = false,
    ...boxProps
  } = props;

  const styles = !below
    ? {
        childWidth: 'min-content',
        overflow: 'auto' as const,
        padding: WRAPPER_PADDING,
      }
    : {
        childWidth: { base: 'min-content', [below]: 'auto' },
        overflow: { base: 'auto', [below]: 'hidden' } as const,
        // make a padding array ending in 0 of the correct length for the size
        // (0 means that there is no canceling effect)
        padding: pipe<[number[]], number[], number[]>(
          take(sizes.findIndex(equals(below)) + 1),
          append(0)
        )(WRAPPER_PADDING),
      };
  const margin = styles.padding.map(when<number, number>(Boolean, negate));

  return (
    <Box
      mx={margin}
      overflowX={styles.overflow}
      {...(withPadding && { px: styles.padding })}
      {...(!withScrollbar && {
        sx: {
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
      {...boxProps}
    >
      {Children.map(children, (child: ReactElement) =>
        cloneElement(child, { w: styles.childWidth })
      )}
    </Box>
  );
};
