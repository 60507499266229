import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from '@ironhack/design-system2/components';
import { ReactElement } from 'react';
import { Image, StructuredTextGraphQlResponse } from 'react-datocms';

import type { DatoBlock, DatoButtonBlock, DatoImage } from '@/lib/datocms';
import { Button, StructuredContent } from '@/components';

import type { AccordionItemProps } from '@ironhack/design-system2/components';

export type ExpandCollapseCard = DatoBlock<{
  button?: [DatoButtonBlock];
  content: StructuredTextGraphQlResponse;
  filterValues?: string[];
  image?: DatoImage;
  title?: string;
}> & { onClick?: () => void; showImage?: boolean; showTitle?: boolean };

type Props = { card: ExpandCollapseCard } & AccordionItemProps;

export const ExpandCollapseCard = (props: Props): ReactElement => {
  const { card, ...accordionItemProps } = props;
  const {
    title,
    image,
    content,
    button: [button] = [],
    showImage = false,
    showTitle = true,
    onClick,
  } = card;

  return (
    <AccordionItem {...accordionItemProps}>
      <AccordionButton>
        <Flex direction="column" flex={1} gap={2}>
          {showTitle && title && (
            <Text
              align="start"
              color="text.primary"
              flex={1}
              fontWeight="medium"
              textStyle="xl"
            >
              {title}
            </Text>
          )}
          {showImage && image && <Image data={image.responsiveImage} />}
        </Flex>
        <AccordionIcon color="icon.dark" />
      </AccordionButton>
      <AccordionPanel as={Flex} direction="column" gap={3}>
        <StructuredContent data={content} gap={2} />
        {button && (
          <Button
            {...(onClick && { onClick })}
            alignSelf="start"
            size="s"
            width={['full', null, 'auto']}
            {...button}
          />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};
