import {
  Flex,
  SelectCard,
  Text,
  chakra,
  forwardRef,
  useRadio,
} from '@ironhack/design-system2/components';

import { useEffect, useState } from 'react';

import type { WebCohort } from '@/types';
import { usePageContext } from '@/hooks';
import { getPrettyCohort } from '@/lib/utils';

import type { UseRadioProps } from '@ironhack/design-system2/components';

type CohortSelectorCardProps = UseRadioProps & {
  cohort: WebCohort;
};

export const CohortSelectorCard = forwardRef<CohortSelectorCardProps, 'div'>(
  (props: CohortSelectorCardProps, ref) => {
    const { cohort, ...useRadioProps } = props;
    const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
      useRadio(useRadioProps);
    const {
      params: { language },
    } = usePageContext();
    const [useCohortTimezone, setUseCohortTimezone] = useState(true);
    // Use cohort timezone on the server to prevent a hydration error
    useEffect(() => setUseCohortTimezone(false), [setUseCohortTimezone]);

    const prettyCohort = getPrettyCohort(cohort, language, {
      useCohortTimezone,
    });

    return (
      <chakra.label
        {...htmlProps}
        cursor="pointer"
        position="relative"
        width={['100%', null, 'auto']}
      >
        <input {...getInputProps({ ref })} />
        <SelectCard {...getCheckboxProps()} isChecked={state.isChecked}>
          <Text textStyle="lBold" {...getLabelProps()}>
            {`${prettyCohort.startDate} - ${prettyCohort.endDate}`}
          </Text>
          <Text color="text.secondary" mt={2} textStyle="mBold">
            {`${prettyCohort.weekDays.join(
              ` ${cohort.format === 'ft' ? '-' : '&'} `
            )}:
             ${prettyCohort.weekDayStartTime} - ${
              prettyCohort.weekDayEndTime
            } (${prettyCohort.timezone})`}
          </Text>
          {cohort.format === 'pt' && (
            <Text color="text.secondary" textStyle="mBold">
              {`${prettyCohort.weekendDays.join(' & ')}:
             ${prettyCohort.weekendStartTime} - ${
                prettyCohort.weekendEndTime
              } (${prettyCohort.timezone})`}
            </Text>
          )}
          <Text color="text.secondary" textStyle="mBold">
            {`${prettyCohort.modality} - ${prettyCohort.language}`}
          </Text>

          <Flex gap={1} mt={2}>
            <Text textStyle="mBold">{`${prettyCohort.discountPrice}`}</Text>
            {prettyCohort.price !== prettyCohort.discountPrice && (
              <Text as="s" color="text.secondary" textStyle="mBold">
                {`${prettyCohort.price}`}
              </Text>
            )}
          </Flex>
        </SelectCard>
      </chakra.label>
    );
  }
);
