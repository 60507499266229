import { setDay } from 'date-fns';
import {
  always,
  anyPass,
  find,
  findLast,
  ifElse,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  pipe,
  reject,
  replace,
  toLower,
  toUpper,
  when,
} from 'ramda';

import type { DatoLocale } from '@/lib/datocms';
import type { Campus, CourseTrack, Language, Region, WebCohort } from '@/types';
import { getCohortTime, getTimezoneAbbreviation } from './dates';

import type { StructuredTextGraphQlResponse } from 'react-datocms';

export const urlToDatoLocale: Record<string, DatoLocale> = {
  bren: 'en_US',
  brpt: 'pt_BR',
  dede: 'de_DE',
  deen: 'en_US',
  esen: 'en_US',
  eses: 'es_ES',
  fren: 'en_US',
  frfr: 'fr_FR',
  mxen: 'en_US',
  mxes: 'es_MX',
  nlen: 'en_US',
  nlnl: 'nl_NL',
  pten: 'en_US',
  ptpt: 'pt_PT',
  uken: 'en_GB',
  usen: 'en_US',
  uses: 'es_ES',
  wwen: 'en_US',
} as const;

export const regionToLanguages: Record<Region, Language[]> = {
  br: ['pt', 'en'],
  de: ['de', 'en'],
  es: ['es', 'en'],
  fr: ['fr', 'en'],
  mx: ['es', 'en'],
  nl: ['nl', 'en'],
  pt: ['pt', 'en'],
  uk: ['en'],
  us: ['en'],
  ww: ['en'],
};

export const toTitleCase = pipe(
  toLower,
  replace(/(^|[\s-])\S/gu, toUpper),
  replace(/-/gu, ' ')
);

export const toUpperPascalCase = pipe(
  toLower,
  replace(/(^|[\s-])\S/gu, toUpper),
  replace(/-/gu, '')
);

export const regionToTimezoneMap: Record<Region, string> = {
  br: 'America/Sao_Paulo',
  de: 'Europe/Madrid',
  es: 'Europe/Madrid',
  fr: 'Europe/Madrid',
  mx: 'America/Mexico_City',
  nl: 'Europe/Madrid',
  pt: 'Europe/Madrid',
  uk: 'Europe/Madrid',
  us: 'America/Chicago',
  ww: '',
};

// The first campus sets the price for remote cohorts in the region
export const regionToCampusMap: Record<Region, Campus[]> = {
  br: ['sao'],
  de: ['ber'],
  es: ['mad', 'bcn'],
  fr: ['par'],
  mx: ['mex'],
  nl: ['ams'],
  pt: ['lis'],
  uk: ['lon', 'rmt'],
  us: ['mia'],
  ww: ['rmt'],
};

export const regionMainLanguage: Record<Region, string> = {
  br: 'pt',
  de: 'de',
  es: 'es',
  fr: 'fr',
  mx: 'es',
  nl: 'nl',
  pt: 'pt',
  uk: 'en',
  us: 'en',
  ww: 'en',
};

/* eslint-disable sort-keys */
export const countryToTimezone = {
  // United States
  US: 'America/Chicago',

  // Brazil
  BR: 'America/Sao_Paulo',

  // Europe
  BE: 'Europe/Madrid',
  BG: 'Europe/Madrid',
  CZ: 'Europe/Madrid',
  DK: 'Europe/Madrid',
  DE: 'Europe/Madrid',
  EE: 'Europe/Madrid',
  IE: 'Europe/Madrid',
  EL: 'Europe/Madrid',
  ES: 'Europe/Madrid',
  FR: 'Europe/Madrid',
  HR: 'Europe/Madrid',
  IT: 'Europe/Madrid',
  CY: 'Europe/Madrid',
  LV: 'Europe/Madrid',
  LT: 'Europe/Madrid',
  LU: 'Europe/Madrid',
  HU: 'Europe/Madrid',
  MT: 'Europe/Madrid',
  NL: 'Europe/Madrid',
  AT: 'Europe/Madrid',
  PL: 'Europe/Madrid',
  PT: 'Europe/Madrid',
  RO: 'Europe/Madrid',
  SI: 'Europe/Madrid',
  SK: 'Europe/Madrid',
  FI: 'Europe/Madrid',
  SE: 'Europe/Madrid',
  IS: 'Europe/Madrid',
  LI: 'Europe/Madrid',
  NO: 'Europe/Madrid',
  CH: 'Europe/Madrid',
  ME: 'Europe/Madrid',
  MK: 'Europe/Madrid',
  AL: 'Europe/Madrid',
  RS: 'Europe/Madrid',
  TR: 'Europe/Madrid',
  BA: 'Europe/Madrid',
  XK: 'Europe/Madrid',
  UK: 'Europe/Madrid',
  GB: 'Europe/Madrid',

  // Latam
  AR: 'America/Mexico_City',
  BO: 'America/Mexico_City',
  CL: 'America/Mexico_City',
  CO: 'America/Mexico_City',
  EC: 'America/Mexico_City',
  PY: 'America/Mexico_City',
  PE: 'America/Mexico_City',
  SR: 'America/Mexico_City',
  UY: 'America/Mexico_City',
  VE: 'America/Mexico_City',
  CR: 'America/Mexico_City',
  SV: 'America/Mexico_City',
  GT: 'America/Mexico_City',
  HN: 'America/Mexico_City',
  MX: 'America/Mexico_City',
  NI: 'America/Mexico_City',
  PA: 'America/Mexico_City',
};
/* eslint-enable sort-keys */

export const commonKeys: Record<Language, Record<string, string>> = {
  br: {
    ams: 'Amsterdão',
    bcn: 'Barcelona',
    ber: 'Berlim',
    cy: 'Cibersegurança',
    da: 'Análise de Dados',
    ft: 'Período integral',
    lis: 'Lisboa',
    lon: 'Londres',
    mad: 'Madri',
    mex: 'Cidade do México',
    mia: 'Miami',
    onCampus: 'No campus',
    par: 'Paris',
    pt: 'Meio período',
    remote: 'Remoto/Online',
    rmt: 'Remoto',
    sao: 'São Paulo',
    ux: 'Design UX/UI',
    wd: 'Desenvolvimento Web',
    weeks: 'Semanas',
  },
  de: {
    ams: 'Amsterdam',
    bcn: 'Barcelona',
    ber: 'Berlin',
    cy: 'Cybersecurity',
    da: 'Data analytics',
    ft: 'Vollzeit',
    lis: 'Lissabon',
    lon: 'London',
    mad: 'Madrid',
    mex: 'Mexiko Stadt',
    mia: 'Miami',
    onCampus: 'Im Campus',
    par: 'Paris',
    pt: 'Teilzeit',
    remote: 'Remote/Online',
    rmt: 'Remote',
    sao: 'São Paulo',
    ux: 'UX/UI Design',
    wd: 'Web development',
    weeks: 'Wochen',
  },
  en: {
    ams: 'Amsterdam',
    bcn: 'Barcelona',
    ber: 'Berlin',
    cy: 'Cybersecurity',
    da: 'Data analytics',
    ft: 'Full time',
    lis: 'Lisbon',
    lon: 'London',
    mad: 'Madrid',
    mex: 'Mexico City',
    mia: 'Miami',
    onCampus: 'In Campus',
    par: 'Paris',
    pt: 'Part time',
    remote: 'Remote/Online',
    rmt: 'Remote',
    sao: 'São Paulo',
    ux: 'UX/UI Design',
    wd: 'Web development',
    weeks: 'Weeks',
  },
  es: {
    ams: 'Amsterdam',
    bcn: 'Barcelona',
    ber: 'Berlín',
    cy: 'Ciberseguridad',
    da: 'Análisis de Datos',
    ft: 'Tiempo completo',
    lis: 'Lisboa',
    lon: 'Londres',
    mad: 'Madrid',
    mex: 'Ciudad de México',
    mia: 'Miami',
    onCampus: 'En campus',
    par: 'Paris',
    pt: 'Tiempo parcial',
    remote: 'Remoto/Online',
    rmt: 'Remoto',
    sao: 'São Paulo',
    ux: 'Diseño UX/UI',
    wd: 'Desarrollo Web',
    weeks: 'Semanas',
  },
  fr: {
    ams: 'Amsterdam',
    bcn: 'Barcelone',
    ber: 'Berlin',
    cy: 'Cybersécurité',
    da: 'Data analyse',
    ft: 'Plein temps',
    lis: 'Lisbonne',
    lon: 'Londres',
    mad: 'Madrid',
    mex: 'Mexico',
    mia: 'Miami',
    onCampus: 'Sur le campus',
    par: 'Paris',
    pt: 'Temps partiel',
    remote: 'À distance/En ligne',
    rmt: 'Remote',
    sao: 'São Paulo',
    ux: 'UX/UI Design',
    wd: 'Développement web',
    weeks: 'Semaines',
  },
  nl: {
    ams: 'Amsterdam',
    bcn: 'Barcelona',
    ber: 'Berlijn',
    cy: 'Cybersecurity',
    da: 'Data analyse',
    ft: 'Full time',
    lis: 'Lissabon',
    lon: 'Londen',
    mad: 'Madrid',
    mex: 'Mexico-Stad',
    mia: 'Miami',
    onCampus: 'Op de campus',
    par: 'Parijs',
    pt: 'Part time',
    remote: 'Remote/Online',
    rmt: 'Remote',
    sao: 'São Paulo',
    ux: 'UX/UI Design',
    wd: 'Web development',
    weeks: 'Weken',
  },
  pt: {
    ams: 'Amsterdão',
    bcn: 'Barcelona',
    ber: 'Berlim',
    cy: 'Cibersegurança',
    da: 'Análise de Dados',
    ft: 'Tempo inteiro',
    lis: 'Lisboa',
    lon: 'Londres',
    mad: 'Madri',
    mex: 'Cidade do México',
    mia: 'Miami',
    onCampus: 'No campus',
    par: 'Paris',
    pt: 'Pós-laboral',
    remote: 'Remoto/Online',
    rmt: 'Remoto',
    sao: 'São Paulo',
    ux: 'Design UX/UI',
    wd: 'Desenvolvimento Web',
    weeks: 'Semanas',
  },
};

export const cleanObject = reject(anyPass([isNil, isEmpty]));

export const regionCodesToEnglishNames: Record<Region, string> = {
  br: 'Brazil',
  de: 'Germany',
  es: 'Spain',
  fr: 'France',
  mx: 'Mexico',
  nl: 'Netherlands',
  pt: 'Portugal',
  uk: 'United Kingdom',
  us: 'United States',
  ww: 'Worldwide',
};

export const getLanguageName = (language: Language, targetLanguage: Language) =>
  new Intl.DisplayNames(targetLanguage, {
    fallback: 'code',
    type: 'language',
  }).of(language) as string;

export const formatCohortDate = (
  cohortDate: string,
  language: Language | DatoLocale
): string =>
  new Date(cohortDate).toLocaleDateString(language.replace('_', '-'), {
    day: 'numeric',
    month: 'short',
    timeZone: 'UTC',
  });

export const getCohortCardIconList = (
  cohort: WebCohort,
  locale: DatoLocale,
  options?: Partial<{ omitItems: string[]; showEndPeriod: boolean }>
): StructuredTextGraphQlResponse => {
  const { omitItems, showEndPeriod } = {
    omitItems: [],
    showEndPeriod: true,
    ...options,
  };
  const startDate = new Date(cohort.start_date);
  const startDateFormatted = formatCohortDate(cohort.start_date, locale);
  const endDate = new Date(cohort.end_date);
  const endDateFormatted = formatCohortDate(cohort.end_date, locale);
  const weeksNumber = Math.round(
    Math.abs(endDate.getTime() - startDate.getTime()) /
      (1000 * 60 * 60 * 24 * 7) // One week in milliseconds
  );
  const language = locale.slice(0, 2) as Language;
  const keys = commonKeys[language];
  const dates = `${startDateFormatted} - ${
    !showEndPeriod ? '' : endDateFormatted
  }`;
  const format = `${keys[cohort.format]} - ${
    !showEndPeriod ? '' : `(${weeksNumber} ${keys.weeks})`
  }`;
  const languageFormatted = getLanguageName(cohort.language, language);
  const location = `${
    keys[cohort.campus === 'rmt' ? 'remote' : 'onCampus']
  } - ${languageFormatted}`;
  const price = cohort.price.amount.toLocaleString(locale.replace('_', '-'), {
    style: 'currency',
    currency: cohort.price.currency,
  });
  const discountPrice = (
    cohort.price.amount - cohort.price.discount || 0
  ).toLocaleString(locale.replace('_', '-'), {
    style: 'currency',
    currency: cohort.price.currency,
  });
  return {
    blocks: [
      {
        __typename: 'ListWithIconsBlockRecord',
        id: '55723067',
        listItems: [
          {
            id: 'dates',
            text: dates,
            icon: [
              {
                __typename: 'IconRecord',
                name: 'calendar',
                color: '',
                placement: '',
              },
            ],
          },
          {
            id: 'format',
            text: format,
            icon: [
              {
                __typename: 'IconRecord',
                name: 'clock',
                color: '',
                placement: '',
              },
            ],
          },
          {
            id: 'location',
            text: location,
            icon: [
              {
                __typename: 'IconRecord',
                name: 'map-pin',
                color: '',
                placement: '',
              },
            ],
          },
          {
            id: 'price',
            text: discountPrice,
            icon: [
              {
                __typename: 'IconRecord',
                name: 'tag',
                color: '',
                placement: '',
              },
            ],
          },
        ].filter(({ id }) => !omitItems.includes(id)),
      },
    ],
    links: [],
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children: [
          {
            item: '55723067',
            type: 'block',
          },
          ...(price !== discountPrice && !omitItems.includes('price')
            ? [
                {
                  children: [
                    {
                      marks: ['strikethrough'],
                      type: 'span' as const,
                      value: price,
                    },
                  ],
                  type: 'paragraph' as const,
                },
              ]
            : []),
        ],
      },
    },
  };
};

export const getPrettyCohort = (
  cohort: WebCohort,
  language: Language,
  options?: Partial<{ useCohortTimezone: boolean }>
) => {
  const timezone = options?.useCohortTimezone
    ? cohort.timezone
    : cohort.campus === 'rmt'
      ? // eslint-disable-next-line new-cap -- It's a JS native lib, so it doesn't apply
        Intl.DateTimeFormat(language).resolvedOptions().timeZone
      : cohort.timezone;
  const showDatesInUserTimezone = cohort.campus === 'rmt';
  const formatTime = getCohortTime(
    cohort.timezone,
    timezone,
    cohort.start_date
  );

  const getCohortTimes = (
    isWeekend: boolean
  ): Exclude<WebCohort['timetable'][number], null> =>
    // @ts-expect-error: Can't figure out why pipe returns unknown
    pipe(
      // @ts-expect-error: Can't figure out how to make ifElse play nice
      ifElse(always(isWeekend), findLast(Boolean), find(Boolean)),
      mapObjIndexed((t: string) => t.padStart(5, '0')),
      when(always(showDatesInUserTimezone), map(formatTime))
    )(cohort.timetable);
  const cohortWeekDayTimes = getCohortTimes(false);
  const cohortWeekendTimes =
    cohort.format === 'ft'
      ? { start_time: '', end_time: '' }
      : getCohortTimes(true);

  const cohortStartDate = new Date(cohort.start_date);
  const dayFormatter = new Intl.DateTimeFormat([language], {
    weekday: 'short',
  });

  const [weekDays, weekendDays] = cohort.timetable.reduce<[string[], string[]]>(
    (acc, times, dayIndex) => {
      const dayName = dayFormatter.format(setDay(cohortStartDate, dayIndex));
      const isWeekend = dayIndex === 0 || dayIndex === 6;
      const addWeekDay =
        times &&
        !isWeekend &&
        (!cohort.timetable[dayIndex - 1] || dayIndex === 5);
      if (addWeekDay) {
        return [[...acc[0], dayName], acc[1]];
      }
      const addWeekendDay = times && isWeekend;
      if (addWeekendDay) {
        return [acc[0], [...acc[1], dayName]];
      }
      return acc;
    },
    [[], []]
  );

  const keys = commonKeys[language];

  return {
    campus: keys[cohort.campus],
    discount: cohort.price.discount.toLocaleString(language, {
      style: 'currency',
      currency: cohort.price.currency,
    }),
    discountPrice: (
      cohort.price.amount - cohort.price.discount || 0
    ).toLocaleString(language, {
      style: 'currency',
      currency: cohort.price.currency,
    }),
    endDate: formatCohortDate(cohort.end_date, language),
    externalIds: cohort.external_ids,
    format: keys[cohort.format],
    id: cohort.id,
    language: getLanguageName(cohort.language, language),
    modality: keys[cohort.campus === 'rmt' ? 'remote' : 'onCampus'],
    price: cohort.price.amount.toLocaleString(language, {
      style: 'currency',
      currency: cohort.price.currency,
    }),
    startDate: formatCohortDate(cohort.start_date, language),
    timezone: getTimezoneAbbreviation(timezone, language),
    track: keys[cohort.track],
    weekDayEndTime: cohortWeekDayTimes.end_time,
    weekDays,
    weekDayStartTime: cohortWeekDayTimes.start_time,
    weekendDays,
    weekendEndTime: cohortWeekendTimes.end_time,
    weekendStartTime: cohortWeekendTimes.start_time,
  };
};

export const getSalesforceCampus = (
  campus: Campus,
  country?: string
): string => {
  const countryToSalesforce: Record<string, string> = {
    br: 'US',
    mx: 'US',
    us: 'US',
  };
  if (campus !== 'rmt') {
    return campus.toUpperCase();
  }
  return `${campus.toUpperCase()}${
    countryToSalesforce[country as string] || 'EU'
  }`;
};

export type PrettyCohort = ReturnType<typeof getPrettyCohort>;

export const createStructuredContent = (str: string) => ({
  value: {
    document: {
      children: [
        {
          type: 'paragraph' as const,
          children: [{ type: 'span' as const, value: str }],
        },
      ],
      type: 'root' as const,
    },
    schema: 'dast' as const,
  },
});

export const regionToCountry = (region: Region) =>
  region === 'ww' ? 'us' : region === 'uk' ? 'gb' : region;

export const courseToBackground: Record<CourseTrack, string> = {
  wd: 'web',
  da: 'data',
  cy: 'cyber',
  ux: 'ux',
};
