import { zodResolver } from '@hookform/resolvers/zod';
import {
  ContentSwitch,
  Flex,
  FormFeedback,
  FormField,
} from '@ironhack/design-system2/components';
import { assoc, omit, pick } from 'ramda';
import { ReactElement, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSessionStorage } from 'react-use';
import * as z from 'zod';

import {
  ContactDetailsFields,
  FormSubmit,
  PhoneField,
  TextareaField,
} from '@/components';
import { useGtmLocalStorage, usePageContext } from '@/hooks';
import { sendEvent } from '@/lib/gtm';
import {
  commonKeys,
  regionToCampusMap,
  regionToCountry,
  toTitleCase,
} from '@/lib/utils';
import type { CampaignForm as CampaignFormType } from '@/modules';
import { SelectField } from '../select-field';

import type { FlexProps } from '@ironhack/design-system2/components';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: { phoneNumber: string; country: string };
  message: string;
  selection: {
    track: string;
    courseType: 'online' | 'in_person';
    campus: string;
    format: 'FT' | 'PT';
    language: string;
  };
};

type Props = {
  campaignForm: CampaignFormType;
  onSuccess: () => void;
  onStep2: () => void;
} & FlexProps;

const getSchema = (
  messages: Record<string, { invalid: string; required: string }>
) =>
  z
    .object({
      email: z.string().email(messages.email.invalid),
      firstName: z.string().min(2, messages.firstName.required),
      lastName: z.string().min(2, messages.lastName.required),
      ...(messages.message && {
        message: z.string().min(2, messages.message?.required),
      }),
      phone: z
        .object(
          {
            phoneNumber: z
              .string({ required_error: messages.phone.required })
              .min(4, messages.phone.invalid),
            country: z.string({ required_error: messages.phone.required }),
          },
          {
            invalid_type_error: messages.phone.invalid,
            required_error: messages.phone.required,
          }
        )
        .refine(async (phone) => {
          const response = await fetch(`/api/phone-validations`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...phone }),
          });
          const {
            result: { valid },
          } = (await response.json()) as {
            result: { valid: boolean };
          };
          return valid;
        }, messages.phone.invalid),
      selection: z
        .object({
          campus: z.string(),
          courseType: z.string(),
          track: z.string().min(2, messages.track.required),
          language: z.string().min(2, messages.language.required),
          format: z.string().min(2, messages.format.required),
        })
        .superRefine((sel, { addIssue }) => {
          if (sel.courseType === 'in_person' && sel.campus === '') {
            addIssue({
              code: z.ZodIssueCode.custom,
              path: ['campus'],
              message: messages.campus.required,
            });
          }
        }),
    })
    .required();

export const CampaignForm = (props: Props): ReactElement => {
  const { campaignForm, onSuccess, onStep2, ...flexProps } = props;
  const {
    buttonText,
    campaignId,
    campuses,
    campusLabel,
    campusPlaceholder,
    campusRequiredMessage,
    courseLabel,
    coursePlaceholder,
    courseRequiredMessage,
    courses,
    courseType: datoCourseType,
    courseTypeLabel,
    errorMessage,
    formats,
    formatLabel,
    formatPlaceholder,
    formatRequiredMessage,
    hasInquiryField,
    inPersonOption,
    inquiryLabel,
    inquiryPlaceholder,
    inquiryRequiredMessage,
    languageLabel,
    languagePlaceholder,
    languageRequiredMessage,
    languages,
    legalText,
    onlineOption,
    phoneLabel,
    phonePlaceholder,
  } = campaignForm;
  const {
    params: { region, language },
    pageData: { gaCategory },
  } = usePageContext();
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [websiteCampusPage] = useSessionStorage(
    'campus',
    regionToCampusMap[region][0]
  );
  const [showInquiry, setshowInquiry] = useState(hasInquiryField);

  const textInputs = ['firstName', 'lastName', 'email', 'phone'];

  const formSchema = getSchema(
    textInputs.reduce(
      (acc, inputName: keyof CampaignFormType) =>
        assoc(
          inputName,
          {
            required: campaignForm[`${inputName}RequiredMessage`] as string,
            invalid: campaignForm[`${inputName}InvalidMessage`] as string,
          },
          acc
        ),
      {
        campus: { required: campusRequiredMessage, invalid: '' },
        format: { required: formatRequiredMessage, invalid: '' },
        language: { required: languageRequiredMessage, invalid: '' },
        ...(hasInquiryField && {
          message: { required: inquiryRequiredMessage, invalid: '' },
        }),
        track: { required: courseRequiredMessage, invalid: '' },
      }
    )
  );

  let defaultCourseType: 'online' | 'in_person' = 'in_person';
  switch (datoCourseType) {
    case 'online':
      defaultCourseType = 'online';
      break;
    case 'in_person':
      defaultCourseType = 'in_person';
      break;
    case 'online+in_person':
      defaultCourseType = websiteCampusPage === 'rmt' ? 'online' : 'in_person';
      break;
    default:
      break;
  }

  const formMethods = useForm<FormData>({
    defaultValues: {
      message: '',
      phone: { country: regionToCountry(region) },
      selection: {
        campus: websiteCampusPage,
        courseType: defaultCourseType,
        track: '',
        language: '',
        format: 'PT',
      },
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(formSchema),
  });
  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    trigger,
    watch,
  } = formMethods;
  const [formFeedback, setFormFeedback] = useState<{
    status: 'error';
    message: string;
  }>();
  const courseType = watch('selection.courseType');
  const [, setGtmLocalStorage] = useGtmLocalStorage();

  const onSubmit = async (formData: FormData): Promise<void> => {
    setFormFeedback(null);
    const {
      email,
      phone: { phoneNumber, country },
      selection: {
        campus: selectedCampus,
        courseType: selectedType,
        track,
        language: selectionLanguage,
        format,
      },
    } = formData;
    try {
      const response = await fetch('/api/appgen', {
        body: JSON.stringify({
          data: {
            ...omit(['selection'], formData),
            cohort: {
              campus: selectedType === 'in_person' ? selectedCampus : 'rmt',
              bootcamp: track.toUpperCase(),
              language: selectionLanguage,
              format,
            },
            campaignId,
            emailOptIn: true,
          },
          formType: 'appgen',
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Error sending form data to Salesforce');
      }

      const eventAction = 'send more info request';
      const eventCategory = gaCategory;

      sendEvent({
        campus: selectedCampus,
        course: track,
        email,
        eventAction,
        eventCategory,
        eventLabel: `${selectedCampus}::${track}`,
        pageType: eventCategory,
      });

      setGtmLocalStorage({
        applicationData: {
          campus: selectedCampus,
          course: track,
          phoneNumber,
          ...pick(['email', 'firstName', 'lastName'], formData),
        },
        countryName: country,
      });
      onSuccess();
    } catch {
      setFormFeedback({ status: 'error', message: errorMessage });
    }
  };

  return (
    <Flex direction="column" {...flexProps}>
      <FormProvider {...formMethods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center" direction="column">
            {step === 1 && (
              <>
                <SelectField
                  error={errors.selection?.track?.message}
                  isRequired
                  label={courseLabel}
                  name="selection.track"
                  options={courses.map((course) => ({
                    key: course.code,
                    text: course.name,
                  }))}
                  placeholder={coursePlaceholder}
                />
                {datoCourseType === 'online+in_person' && (
                  <FormField
                    error=""
                    label={courseTypeLabel}
                    mt={3}
                    name="courseType"
                  >
                    <Controller
                      control={control}
                      defaultValue="online"
                      name="selection.courseType"
                      render={({ field: { onChange, value, ref } }) => (
                        <ContentSwitch
                          onChange={onChange}
                          options={[
                            { key: 'online', text: onlineOption },
                            { key: 'in_person', text: inPersonOption },
                          ]}
                          ref={ref}
                          value={value}
                        />
                      )}
                    />
                  </FormField>
                )}
                {courseType === 'in_person' && (
                  <SelectField
                    error={errors.selection?.campus?.message}
                    isRequired
                    label={campusLabel}
                    mt={4}
                    name="selection.campus"
                    options={campuses.map(({ code, name }) => ({
                      key: code,
                      text: name,
                    }))}
                    placeholder={campusPlaceholder}
                  />
                )}
                <SelectField
                  error={errors.selection?.format?.message}
                  isRequired
                  label={formatLabel}
                  mt={4}
                  name="selection.format"
                  options={formats.map(({ code }) => ({
                    key: code,
                    text: commonKeys[language][code.toLowerCase()],
                  }))}
                  placeholder={formatPlaceholder}
                />
                <SelectField
                  error={errors.selection?.language?.message}
                  isRequired
                  label={languageLabel}
                  mt={4}
                  name="selection.language"
                  options={languages.map(({ code }) => ({
                    key: code,
                    text: toTitleCase(
                      new Intl.DisplayNames([language], {
                        type: 'language',
                      }).of(code.slice(0, code.indexOf('_')))
                    ),
                  }))}
                  placeholder={languagePlaceholder}
                />
                <ContactDetailsFields
                  fields={pick(
                    [
                      'chooseBetween',
                      'completeFields',
                      'emailLabel',
                      'emailPlaceholder',
                      'firstNameLabel',
                      'firstNamePlaceholder',
                      'lastNameLabel',
                      'lastNamePlaceholder',
                      'phoneLabel',
                      'phonePlaceholder',
                    ],
                    campaignForm
                  )}
                  mt={4}
                  onGoogleClick={() => {
                    setFormFeedback(null);
                    setIsGoogleSubmitting(true);
                  }}
                  onGoogleError={() => {
                    setIsGoogleSubmitting(false);
                    setFormFeedback({
                      status: 'error',
                      message: errorMessage,
                    });
                  }}
                  onGoogleSuccess={async (googleData) => {
                    setIsGoogleSubmitting(false);
                    const isValid = await trigger('selection');
                    if (isValid) {
                      setValue('email', googleData.email);
                      setValue('firstName', googleData.firstName);
                      setValue('lastName', googleData.lastName);
                      clearErrors();
                      if (getValues('message')) {
                        setshowInquiry(false);
                      }
                      setStep(2);
                      onStep2();
                    }
                  }}
                  setFormValues={false}
                  withPhone
                />
              </>
            )}
            {step === 2 && (
              <PhoneField
                error={
                  errors.phone?.message || errors.phone?.phoneNumber?.message
                }
                isRequired
                label={phoneLabel}
                name="phone"
                placeholder={phonePlaceholder}
              />
            )}
            {showInquiry && (
              <TextareaField
                error={errors.message?.message}
                isRequired
                key="message"
                label={inquiryLabel}
                mt={2}
                name="message"
                placeholder={inquiryPlaceholder}
              />
            )}
            <FormSubmit
              buttonText={buttonText}
              isLoading={isSubmitting || isGoogleSubmitting}
              legalText={legalText}
              mt={[2, null, 3]}
            />
            {formFeedback?.status && <FormFeedback mt={2} {...formFeedback} />}
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
};
