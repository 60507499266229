import { Box, Flex, Text } from '@ironhack/design-system2/components';
import NextImage from 'next/image';
import { Image } from 'react-datocms';

import type { DatoStory } from '@/lib/datocms';

import type { ReactElement } from 'react';
import type { FlexProps } from '@ironhack/design-system2/components';

type Props = { story: DatoStory; onClick: () => void } & FlexProps;

export const StoryThumbnail = (props: Props): ReactElement => {
  const { story, onClick, ...flexProps } = props;
  const { userName, userPicture, userRole, company, video } = story;

  return (
    <Flex
      alignItems="center"
      direction="column"
      gap={1}
      onClick={onClick}
      w="132px"
      {...flexProps}
    >
      <Flex
        borderRadius="lg"
        direction="column"
        h="244px"
        overflow="hidden"
        position="relative"
        w="132px"
      >
        <Box
          _before={{
            backgroundImage:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 40%)',
            content: '""',
            height: '100%',
            left: '0',
            position: 'absolute',
            width: '100%',
            zIndex: '1',
          }}
          h="full"
          left={0}
          position="absolute"
          top={0}
        >
          <NextImage
            alt={video.alt}
            height={244}
            src={video.video.thumbnailUrl}
            width={132}
          />
        </Box>
        <Flex
          alignItems="center"
          color="text.inverse"
          direction="column"
          h="full"
          justifyContent="flex-end"
          p={1}
          w="full"
          zIndex={1}
        >
          <Box
            background="linear-gradient(#fff, #fff) padding-box, var(--chakra-colors-background-brand) border-box;"
            border="3px solid transparent"
            borderRadius="full"
            boxSize="54px"
            overflow="hidden"
          >
            <Image data={userPicture.responsiveImage} />
          </Box>
          <Text
            mt={1}
            noOfLines={1}
            textAlign="center"
            textStyle="lBold"
            zIndex={1}
          >
            {userName}
          </Text>
          <Text
            mt={0.5}
            noOfLines={1}
            textAlign="center"
            textStyle="s"
            zIndex={1}
          >
            {userRole}
          </Text>
        </Flex>
      </Flex>
      <Image data={company.horizontalBackgroundLogo.responsiveImage} />
    </Flex>
  );
};
